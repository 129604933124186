import React, { useState, useCallback, useEffect, FC, ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { spacing, breakpoints, fontStyle, themeLight, radius } from '@naf/theme';
import { Close } from '@styled-icons/material/Close';
import { Text, TextVariant } from '@naf/text';

export { DialogIE } from './dialogIE';
export type { DialogIEProps } from './dialogIE';

export interface DialogProps {
  /**
   * Set the state of the dialog box to open or closed.
   */
  isOpen?: boolean;

  /**
   * Set the size of the dialog box.
   */
  size?: 'default' | 'small';

  /**
   * Set to render button(s) in the dialog box.
   */
  button?: ReactNode;

  /**
   * Function for closing the dialog box. Typical use pattern will be to change state on `isOpen`.
   */
  handleClose: () => void;

  /**
   * Set a title for the dialog box.
   */
  title?: string;

  /**
   * Set to remove the cross/close button from the dialog box.
   */
  closeIcon?: boolean;

  /**
   * Image source (either URL or local file).
   */
  img?: string;

  /**
   * Locks focus for tabbing in the dialog box. Default is `true`.
   */
  trapFocus?: boolean;

  children: any;
  className?: string;
}

export const Dialog: FC<DialogProps> = React.memo(
  ({
    isOpen = false,
    size = 'default',
    children,
    button,
    handleClose,
    title,
    closeIcon = true,
    img,
    trapFocus = true,
    className,
  }: DialogProps) => {
    const [mouseDown, setMouseDown] = useState(false);

    useEffect(() => {
      if (isOpen && trapFocus) {
        const element = document.querySelector('#dialog');
        if (element) {
          let focusables = element.querySelectorAll(
            'a[href]:not([disabled]), button:not([disabled]), textarea:not([disabled]), input:not([disabled]), select:not([disabled]), iframe, object, [tabindex="0"], [contenteditable]',
          );
          const firstFocusable = focusables[0];
          const lastFocusable = focusables[focusables.length - 1];

          (firstFocusable as HTMLElement).focus();

          const trapFocus = (e: KeyboardEvent) => {
            if (e.key === 'Tab') {
              if (e.shiftKey && document.activeElement === firstFocusable) {
                /* shift + tab */
                (lastFocusable as HTMLElement).focus();
                e.preventDefault();
              } else if (document.activeElement === lastFocusable) {
                /* tab */
                (firstFocusable as HTMLElement).focus();
                e.preventDefault();
              }
            }
          };

          document.addEventListener('keydown', trapFocus);
          return () => document.removeEventListener('keydown', trapFocus);
        }
        return () => {};
      }
      return () => {};
    }, [isOpen, trapFocus]);

    const removeOutline = () => {
      setMouseDown(true);
    };

    const addOutline = (e: KeyboardEvent) => {
      if (e.key === 'Tab') {
        setMouseDown(false);
      }
    };

    useEffect(() => {
      window.addEventListener('mousedown', removeOutline);

      return () => window.removeEventListener('mousedown', removeOutline);
    }, []);

    const close = useCallback(
      (e: { key: string }) => {
        if (e.key === 'Escape') {
          handleClose();
        }
      },
      [handleClose],
    );

    useEffect(() => {
      window.addEventListener('keydown', (e: KeyboardEvent) => {
        close(e);
        addOutline(e);
      });
      return () =>
        window.removeEventListener('keydown', (e: KeyboardEvent) => {
          close(e);
          addOutline(e);
        });
    }, [close]);

    return (
      <>
        {isOpen && (
          <>
            <Overlay onClick={() => handleClose()} />
            <ContentWrapper id="dialog">
              <Container className={className} id={mouseDown ? 'mousedown' : ''} size={size} aria-modal={isOpen}>
                <Header>
                  {closeIcon && (
                    <button onClick={() => handleClose()} aria-label="Lukk dialogboks">
                      <Close size={24} />
                    </button>
                  )}
                </Header>
                <Inner>
                  {img && <img src={img} alt="Illustrasjon" />}
                  {title && <Text variant={TextVariant.Header3}>{title}</Text>}
                  {children}
                </Inner>
                {button && <Footer>{button}</Footer>}
              </Container>
            </ContentWrapper>
          </>
        )}
      </>
    );
  },
);

export default Dialog;

const ContentWrapper = styled.div``;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: 'rgba(6, 46, 42, 0.5)';
  z-index: 99;
  animation: opacity 0.25s;

  @keyframes opacity {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

const Container = styled.div<{ size: string }>`
  display: flex;
  flex-wrap: wrap;
  background-color: ${({ theme }) => (theme.background ? theme.background.default : themeLight.background.default)};
  position: fixed;
  top: 50%;
  left: 50%;
  overflow-y: auto;
  z-index: 999;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.15);
  transform: translate(-50%, -50%);
  ${fontStyle.article.articleText};
  padding: 0 0 ${spacing.space48};
  border-radius: ${radius.s};
  animation: opacity 0.25s;

  @keyframes opacity {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  /* Default */
  ${({ size }) =>
    size === 'default' &&
    css`
      max-width: 560px;
      max-height: 72%;
    `}

  /* Small */
    ${({ size }) =>
    size === 'small' &&
    css`
      max-width: 420px;
      max-height: 48%;
    `}

    @media (max-width: ${breakpoints.s}) {
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    top: auto;
    max-height: 100%;
    transform: none;
    ${fontStyle.bodyText.bodyText};
    padding: 0 0 ${spacing.space40};
  }

  &#mousedown *:focus {
    outline: 0;
  }
`;

const Header = styled.div`
  flex-basis: 100%;
  height: ${spacing.space48};
  display: flex;
  justify-content: flex-end;

  button {
    margin-right: ${spacing.space16};
    margin-top: ${spacing.space16};
    padding: 0;
    border: none;
    background-color: inherit;
    touch-action: manipulation;
    height: ${spacing.space24};

    &:hover {
      cursor: pointer;
    }
  }
`;

const Inner = styled.div`
  margin: 0 ${spacing.space48};
  display: flex;
  flex-direction: column;

  img {
    margin-bottom: ${spacing.space32};
    max-width: 50%;
    object-fit: contain;

    @media (max-width: ${breakpoints.s}) {
      margin-bottom: ${spacing.space12};
    }
  }

  h3 {
    margin: 0 0 ${spacing.space16};
  }

  @media (max-width: ${breakpoints.s}) {
    margin: 0 ${spacing.space24};
  }
`;

const Footer = styled.div`
  flex-basis: 100%;
  margin: ${spacing.space32} ${spacing.space48} 0;

  button {
    margin-right: ${spacing.space16};
  }

  @media (max-width: ${breakpoints.s}) {
    margin: ${spacing.space32} ${spacing.space24} 0;
  }
`;

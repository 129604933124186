import React, { useState, useEffect, useRef, useCallback, FC } from 'react';
import styled, { css } from 'styled-components';
import { fontStyle, spacing, themeLight, radius, breakpoints } from '@naf/theme';
import { nafColor } from '@nafcore/theme';
import { ExpandMore } from '@styled-icons/material/ExpandMore';

export interface DropdownProps {
  /**
   * List over countries and country codes for the dropdown.
   */
  countries: {
    code: string;
    name: string;
  }[];

  /**
   * Country that will be shown at the top of the dropdown.
   */
  preferredCountries?: {
    code: string;
    name: string;
  }[];

  /**
   * How you want to handle the selected country.
   */
  handleSelect: any;

  /**
   * The selected country.
   */
  selected: {
    name: string;
    code: string;
  };

  /**
   * Set the width of the dropdown.
   */
  width: number;

  /**
   * Set the dropdown as inactive and changes styling.
   */
  disabled: boolean;
}

export const Dropdown: FC<DropdownProps> = ({
  countries,
  preferredCountries,
  handleSelect,
  selected,
  width,
  disabled,
}: DropdownProps) => {
  const [open, setOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const onSelect = (country: { name: string; code: string }) => {
    handleSelect(country);
    setOpen(false);
  };

  const handleClickOutside = (e: any) => {
    if (!!dropdownRef.current && !dropdownRef.current.contains(e.target)) {
      setOpen(false);
    }
  };

  const handleEscape = useCallback((e: { key: string }) => {
    if (e.key === 'Escape') {
      setOpen(false);
    }
  }, []);

  useEffect(() => {
    window.addEventListener('keydown', (e: KeyboardEvent) => handleEscape(e));
    return () => {
      window.removeEventListener('keydown', (e: KeyboardEvent) => handleEscape(e));
    };
  }, [handleEscape]);

  useEffect(() => {
    window.addEventListener('mousedown', (e: MouseEvent) => handleClickOutside(e));
    return () => {
      window.removeEventListener('mousedown', (e: MouseEvent) => handleClickOutside(e));
    };
  }, []);

  return (
    <Container ref={dropdownRef} aria-expanded={open} aria-haspopup="listbox">
      <ButtonWrap>
        <Button onClick={() => setOpen(!open)} open={open} type="button" disabled={disabled}>
          {'+' + selected.code} <ExpandMore size={24} />
        </Button>
      </ButtonWrap>
      {open && countries && (
        <ListContainer width={width}>
          {preferredCountries ? (
            <UL>
              {preferredCountries.map((country: { name: string; code: string }) => (
                <TopLi key={country.code}>
                  <Option onClick={() => onSelect(country)} selected={selected && selected.code === country.code}>
                    {country.name}
                  </Option>
                </TopLi>
              ))}
            </UL>
          ) : null}
          <UL>
            {countries.map((country: { name: string; code: string }) => (
              <BottomLi key={country.code}>
                <Option onClick={() => onSelect(country)} selected={selected && selected.code === country.code}>
                  {country.name}
                </Option>
              </BottomLi>
            ))}
          </UL>
        </ListContainer>
      )}
    </Container>
  );
};

export default Dropdown;

const Container = styled.div`
  position: relative;
  display: inline-flex;
  flex-direction: column;
  height: 100%;
`;

const ButtonWrap = styled.div`
  display: flex;
  height: 100%;
`;

const Button = styled.button<any>`
  background: transparent;
  border: 0;
  padding: 0;
  padding-left: ${spacing.space12};
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  cursor: pointer;

  &:after {
    content: '';
    border-right: 1px solid;
    border-color: ${({ theme }) =>
      theme.typography ? theme.typography.defaultText : themeLight.typography.defaultText};
    height: calc(${fontStyle.article.articleText['font-size']} * ${fontStyle.article.articleText['line-height']});
    margin-left: ${spacing.space4};
  }

  &:focus {
    outline: none !important;
    border: 2px solid;
    margin: -1px;
    border-color: ${({ theme }) =>
      theme.typography ? theme.typography.defaultText : themeLight.typography.defaultText} !important;
    border-radius: ${radius.s} 0 0 ${radius.s} !important;
    padding-left: calc(${spacing.space12} - 1px);

    &:after {
      border: none;
    }
  }

  svg {
    margin-left: 2px;
  }

  ${({ open }) =>
    open &&
    css`
      svg {
        transform: scaleY(-1);
      }
    `}

  @media (max-width: ${breakpoints.s}) {
    ${fontStyle.bodyText.small};
  }

  ${({ disabled }) =>
    disabled &&
    css`
      color: ${({ theme }) => (theme.typography ? theme.typography.disabledText : themeLight.typography.disabledText)};
      cursor: not-allowed;
    `}
`;

const ListContainer = styled.div<any>`
  position: absolute;
  top: ${spacing.space48};
  margin-top: ${spacing.space8};
  left: 0;
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.24);
  border-radius: ${radius.s};
  overflow-y: auto;
  z-index: 99;
  width: ${(props) => props.width}px;
  max-height: calc(${spacing.space48} * 5.5);
  background: ${({ theme }) => (theme.background ? theme.background.default : themeLight.background.default)};

  ::-webkit-scrollbar {
    width: ${spacing.space16};
  }
  ::-webkit-scrollbar-thumb {
    border: ${spacing.space4} solid transparent;
    background-clip: padding-box;
    border-radius: ${spacing.space8};
    background-color: #e8e8e8;
  }
`;

const UL = styled.ul`
  padding: 0;
  list-style: none;
  margin: 0;
`;

const TopLi = styled.li`
  padding: 0;
  list-style: none;

  &:last-child {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  }
`;

const BottomLi = styled.li`
  padding: 0;
  list-style: none;
`;

const Option = styled.button<{ selected: boolean }>`
  width: 100%;
  text-align: start;
  white-space: nowrap;
  border: 0;
  padding: 0 ${spacing.space12};
  box-sizing: border-box;
  appearance: none;
  height: ${spacing.space48};
  background: transparent;
  ${fontStyle.bodyText.bodyText};

  &:hover {
    cursor: pointer;
  }

  &:hover,
  &:focus {
    background: ${nafColor.signature.yellow10};
  }
`;

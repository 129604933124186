import React, { useCallback, useRef, forwardRef, ForwardRefExoticComponent, RefAttributes } from 'react';
import styled, { css } from 'styled-components';
import { fontStyle, spacing, themeLight, radius, breakpoints } from '@naf/theme';

export interface RadioProps {
  /**
   * Tekst på radioknappen.
   */
  label?: string;
  /**
   * Funksjon når radioknappen trykkes.
   */
  onChange?: () => void;
  /**
   * Navn for å gruppere radioknapper som hører sammen. Vil ikke rendres på siden.
   */
  name: string;
  /**
   * Setter radioknappens state til error og endrer stylingen.
   */
  error?: boolean;
  /**
   * Setter radioknappens state til disabled, inaktiverer handlinger og endrer stylingen.
   */
  disabled?: boolean;
  /**
   * Setter en ramme rundt radioknappen.
   */
  outline?: boolean;
  /**
   * Setter radioknappens bredde relativt til parent container. Brukes sammen med `outline`.
   */
  width?: 'content' | 'half' | 'full';
  /**
   * En liten informasjonstekst plassert under label. Brukes f.eks for å vise feilmeldinger.
   */
  message?: string;
  /**
   * Andre props man vil sende til 'RadioButton`.
   */
  [rest: string]: any;
  className?: string;
}

export const RadioButton: ForwardRefExoticComponent<RadioProps & RefAttributes<HTMLDivElement>> = forwardRef<
  HTMLDivElement,
  RadioProps
>(
  (
    {
      label,
      name,
      onChange = () => {},
      error = false,
      disabled = false,
      width = 'content',
      outline = false,
      message,
      className,
      ...rest
    }: RadioProps,
    ref,
  ) => {
    const radioRef = useRef<HTMLInputElement>(null);

    const handleChange = useCallback(() => {
      onChange();
    }, [onChange]);

    const handleRadioClick = useCallback(
      (isActive: boolean) => {
        if (isActive) {
          handleChange();
          if (!!radioRef && !!radioRef.current) {
            if (!radioRef.current.checked) {
              radioRef.current.checked = true;
            }
          }
        }
      },
      [handleChange, radioRef],
    );

    return (
      <Container
        ref={ref}
        outline={outline}
        onClick={() => !disabled && handleRadioClick(outline)}
        width={width}
        className={className}
      >
        <Label error={error} disabled={disabled}>
          <input
            name={name}
            type="radio"
            tabIndex={-1}
            onChange={() => !disabled && handleChange()}
            disabled={disabled}
            ref={radioRef}
            {...rest}
          />
          <RadioWrap>
            <StyledRadio
              tabIndex={disabled ? -1 : 0}
              onKeyDown={(e: KeyboardEvent) => {
                if (e.key === 'Enter') {
                  handleRadioClick(true);
                }
              }}
            />
          </RadioWrap>
          {label ? <span>{label}</span> : null}
        </Label>
        {message && (
          <Message error={error} disabled={disabled}>
            {message}
          </Message>
        )}
      </Container>
    );
  },
);

export default RadioButton;

const Container = styled.div<any>`
  display: inline-flex;
  align-items: flex-start;
  flex-direction: column;
  margin: 0;
  width: fit-content;

  ${({ outline }) =>
    outline &&
    css`
      padding: ${spacing.space12};
      padding-right: ${spacing.space64};
      padding-bottom: ${spacing.space16};
      border: 1px solid;
      border-radius: ${radius.s};
      border-color: ${({ theme }) => (theme.border ? theme.border.default : themeLight.border.default)};
      &:hover {
        cursor: pointer;
        input ~ div {
          border-color: ${({ theme }) => (theme.border ? theme.border.default : themeLight.border.default)};
        }
      }
    `}

  ${({ width }) =>
    width === 'half' &&
    css`
      padding-right: 0;
      width: calc(50% - ${spacing.space12});
    `}

  ${({ width }) =>
    width === 'full' &&
    css`
      padding-right: 0;
      width: calc(100% - ${spacing.space12});
    `}

  @media (max-width: ${breakpoints.s}) {
    flex-flow: row wrap;
    align-items: center;
    width: calc(100% - 2 * (${spacing.space8}) - ${spacing.space12});
  }
`;

const RadioWrap = styled.div`
  display: inline-flex;
  width: fit-content;
  height: calc(${fontStyle.article.articleText['font-size']} * ${fontStyle.article.articleText['line-height']});
  align-items: center;

  @media (max-width: ${breakpoints.s}) {
    height: calc(${fontStyle.bodyText.bodyText['font-size']} * ${fontStyle.bodyText.bodyText['line-height']});
  }
`;

const StyledRadio = styled.div<any>`
  min-width: ${spacing.space24};
  width: ${spacing.space24};
  height: ${spacing.space24};
  border-radius: 50%;
  box-sizing: border-box;

  &:active {
    border-radius: 50% !important;
  }
`;

const Label = styled.label<{ error: boolean; disabled: boolean }>`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  height: fit-content;
  user-select: none;

  input {
    opacity: 0;
    position: absolute;
    z-index: -1000;
  }

  &:hover {
    cursor: pointer;

    ${({ disabled }) =>
      !disabled &&
      css`
        div {
          border-color: ${({ theme }) => (theme.border ? theme.border.default : themeLight.border.default)};
        }
      `}

    ${({ disabled }) =>
      disabled &&
      css`
        cursor: not-allowed;
      `}
  }

  ${StyledRadio} {
    border: 2px solid;
    background: ${({ theme }) => (theme.background ? theme.background.default : themeLight.background.default)};
    border-color: ${({ theme }) => (theme.border ? theme.border.heavy : themeLight.border.heavy)};

    ${({ error }) =>
      error &&
      css`
        background: ${({ theme }) => (theme.background ? theme.background.default : themeLight.background.default)};
        border-color: ${({ theme }) => (theme.border ? theme.border.default : themeLight.border.default)};
      `}

    ${({ disabled }) =>
      disabled &&
      css`
        background: ${({ theme }) => (theme.background ? theme.background.default : themeLight.background.default)};
        border-color: ${({ theme }) => (theme.border ? theme.border.default : themeLight.border.default)};
      `}
  }

  input:checked ~ div > ${StyledRadio} {
    border: ${spacing.space8} solid;
    border-color: ${({ theme }) =>
      theme.componentColors
        ? theme.componentColors.interactiveElement.active
        : themeLight.componentColors.interactiveElement.active};
    background: ${({ theme }) => (theme.typography ? theme.typography.defaultText : themeLight.typography.defaultText)};

    ${({ error }) =>
      error &&
      css`
        border-color: ${({ theme }) =>
          theme.componentColors ? theme.componentColors.alert.error : themeLight.componentColors.alert.error};
        background: ${({ theme }) =>
          theme.componentColors
            ? theme.componentColors.alert.errorBackground
            : themeLight.componentColors.alert.errorBackground};
      `}

    ${({ disabled }) =>
      disabled &&
      css`
        border-color: ${({ theme }) => (theme.border ? theme.border.default : themeLight.border.default)};
        background: ${({ theme }) =>
          theme.typography ? theme.typography.disabledText : themeLight.typography.disabledText};
      `}
  }

  span {
    ${fontStyle.article.articleText};
    margin-left: ${spacing.space8};

    @media (max-width: ${breakpoints.s}) {
      ${fontStyle.bodyText.bodyText};
    }
  }
`;

const Message = styled.p<{ error: boolean; disabled: boolean }>`
  ${fontStyle.bodyText.small};
  line-height: ${spacing.space16};
  margin: 0;
  margin-left: calc(${spacing.space24} + ${spacing.space8});
  color: ${({ theme }) => (theme.typography ? theme.typography.subtleText : themeLight.typography.subtleText)};

  ${({ error }) =>
    error &&
    css`
      color: ${({ theme }) =>
        theme.componentColors ? theme.componentColors.alert.error : themeLight.componentColors.alert.error};
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      color: ${({ theme }) => (theme.typography ? theme.typography.disabledText : themeLight.typography.disabledText)};
    `}

  @media (max-width: ${breakpoints.s}) {
    margin-left: ${spacing.space12};
  }
`;

import { DesktopFontStyles } from './fontTypes';

export const desktopFontStyles: DesktopFontStyles = {
  html: {
    'font-size': '100%',
  },
  headers: {
    display: {
      'font-size': '4rem',
      'line-height': '4.5rem',
      'font-weight': '900',
      'letter-spacing': '0rem',
      'font-style': 'normal',
    },
    giga: {
      'font-size': 'clamp(2.5rem, 10vw, 7rem)',
      'line-height': '7.5rem',
      'font-weight': '900',
      'letter-spacing': '0rem',
      'font-style': 'normal',
    },
    header1: {
      'font-size': '3rem',
      'line-height': '3.5rem',
      'font-weight': '900',
      'letter-spacing': '0rem',
      'font-style': 'normal',
    },
    header2: {
      'font-size': '1.75rem',
      'line-height': '2.25rem',
      'font-weight': '900',
      'letter-spacing': '0rem',
      'font-style': 'normal',
    },
    header3: {
      'font-size': '1.375rem',
      'line-height': '2rem',
      'font-weight': '700',
      'letter-spacing': '0rem',
      'font-style': 'normal',
    },
    subHeader: {
      'font-size': '1.375rem',
      'line-height': '2rem',
      'font-weight': '500',
      'letter-spacing': '0rem',
      'font-style': 'normal',
    },
  },
  article: {
    articleText: {
      'font-size': '1.125rem',
      'line-height': '1.75rem',
      'font-weight': '300',
      'letter-spacing': '0rem',
      'font-style': 'normal',
    },
    articleTextHeader: {
      'font-size': '1.125rem',
      'line-height': '1.75rem',
      'font-weight': '700',
      'letter-spacing': '0rem',
      'font-style': 'normal',
    },
    articleTextSubHeader: {
      'font-size': '1.125rem',
      'line-height': '1.75rem',
      'font-weight': '500',
      'letter-spacing': '0rem',
      'font-style': 'normal',
    },
    articleTextLarge: {
      'font-size': '1.25rem',
      'line-height': '2rem',
      'font-weight': '300',
      'letter-spacing': '0rem',
      'font-style': 'normal',
    },
    articleTextLargeHeader: {
      'font-size': '1.125rem',
      'line-height': '2rem',
      'font-weight': '700',
      'letter-spacing': '0rem',
      'font-style': 'normal',
    },
    caption: {
      'font-size': '0.9375rem',
      'line-height': '1.25rem',
      'font-weight': '300',
      'letter-spacing': '0rem',
      'font-style': 'italic',
    },
    captionHeader: {
      'font-size': '0.9375rem',
      'line-height': '1.25rem',
      'font-weight': '700',
      'letter-spacing': '0rem',
      'font-style': 'normal',
    },
    ingress: {
      'font-size': '1.625rem',
      'line-height': '2.5rem',
      'font-weight': '400',
      'letter-spacing': '0rem',
      'font-style': 'italic',
    },
    blockquote: {
      'font-size': '1.75rem',
      'line-height': '2.25rem',
      'font-weight': '400',
      'letter-spacing': '0rem',
      'font-style': 'italic',
    },
    blockquoteLarge: {
      'font-size': '2.25rem',
      'line-height': '3.25rem',
      'font-weight': '300',
      'letter-spacing': '0rem',
      'font-style': 'italic',
    },
  },
  bodyText: {
    bodyText: {
      'font-size': '1.125rem',
      'line-height': '1.5rem',
      'font-weight': '300',
      'letter-spacing': '0rem',
      'font-style': 'normal',
    },
    bodyTextHeader: {
      'font-size': '1.125rem',
      'line-height': '1.5rem',
      'font-weight': '700',
      'letter-spacing': '0rem',
      'font-style': 'normal',
    },
    bodyTextSubHeader: {
      'font-size': '1.125rem',
      'line-height': '1.5rem',
      'font-weight': '500',
      'letter-spacing': '0rem',
      'font-style': 'normal',
    },
    small: {
      'font-size': '1rem',
      'line-height': '1.25rem',
      'font-weight': '400',
      'letter-spacing': '0rem',
      'font-style': 'normal',
    },
    tiny: {
      'font-size': '0.75rem',
      'line-height': '1rem',
      'font-weight': '300',
      'letter-spacing': '0rem',
      'font-style': 'normal',
    },
  },
  componentText: {
    cardHeader: {
      'font-size': '1.5rem',
      'line-height': '2rem',
      'font-weight': '700',
      'letter-spacing': '0rem',
      'font-style': 'normal',
    },
    tag: {
      'font-size': '0.875rem',
      'line-height': '1rem',
      'font-weight': '700',
      'letter-spacing': '0.04em',
      'font-style': 'normal',
      'text-transform': 'uppercase',
    },
    tagSmall: {
      'font-size': '0.75rem',
      'line-height': '0.75rem',
      'font-weight': '700',
      'letter-spacing': '0.04em',
      'font-style': 'normal',
      'text-transform': 'uppercase',
    },
    button: {
      'font-size': '1.125rem',
      'line-height': '1.125rem',
      'font-weight': '500',
      'letter-spacing': '0rem',
      'font-style': 'normal',
    },
    buttonSmall: {
      'font-size': '1rem',
      'line-height': '1rem',
      'font-weight': '500',
      'letter-spacing': '0rem',
      'font-style': 'normal',
    },
    dataLabel: {
      'font-size': '0.875rem',
      'line-height': '0.875rem',
      'font-weight': '500',
      'letter-spacing': '0rem',
      'font-style': 'normal',
    },
  },
};

import React, {
  ReactNode,
  useEffect,
  useState,
  forwardRef,
  ForwardRefExoticComponent,
  RefAttributes,
} from 'react';
import styled, { css } from 'styled-components';
import { spacing, breakpoints, themeLight, icons } from '@naf/theme';
import { Text, TextVariant } from '@naf/text';
import { ArrowDownward } from '@styled-icons/material/ArrowDownward';
import { ArrowBack } from '@styled-icons/material/ArrowBack';
import { ArrowForward } from '@styled-icons/material/ArrowForward';
import { Launch } from '@styled-icons/material/Launch';
import { Map } from '@styled-icons/material/Map';

type TargetType = '_blank' | '_self' | '_parent' | '_top' | 'framename';
type VariantType = 'primary' | 'secondary' | 'inverted';
type IconType = 'arrow-back' | 'arrow-forward' | 'arrow-down' | 'launch';
type SizeType = 'standard' | 'small';

export interface ButtonLinkProps {
  children?: ReactNode;
  /**
   * Set an url for the link.
   */
  href?: string;

  /**
   * Set to specify which function to run - typically for internal links.
   */
  onClick?: (e?: any) => void;

  /**
   * Set to specify where the link opens.
   */
  target?: TargetType;

  /**
   * Set to specify if the link is disabled. Changes the styling of the component.
   */
  disabled?: boolean;

  /**
   * Set variant to specify the styling of the link. Gets colors from NAF's designguide.
   */
  variant?: VariantType;

  /**
   * Text of the link.
   */
  text?: string;

  /**
   * Set an icon to emphasize the link's action.
   */
  icon?: IconType;

  /**
   * Set the placement of the icon. Default is suffix.
   */
  prefixIcon?: boolean;

  /**
   * Set the font size of the text.
   */
  size?: SizeType;

  /**
   * Other props you want to send to `ButtonLink`.
   */
  [rest: string]: any;
  className?: string;
}

export const ButtonLink: ForwardRefExoticComponent<
  ButtonLinkProps & RefAttributes<HTMLDivElement>
> = forwardRef<HTMLDivElement, ButtonLinkProps>(
  (
    {
      href,
      onClick,
      target,
      disabled = false,
      variant = 'primary',
      text,
      icon,
      prefixIcon = false,
      size,
      className,
      ...rest
    }: ButtonLinkProps,
    ref,
  ) => {
    const [mouseDown, setMouseDown] = useState(false);

    const removeOutline = () => {
      setMouseDown(true);
    };

    const addOutline = (e: KeyboardEvent) => {
      if (e.key === 'Tab') {
        setMouseDown(false);
      }
    };

    const renderIcon = (icon: string) => {
      switch (icon) {
        case 'arrow-back':
          return <ArrowBack size={16} />;
        case 'arrow-forward':
          return <ArrowForward size={16} />;
        case 'arrow-down':
          return <ArrowDownward size={16} />;
        case 'launch':
          return <Launch size={16} />;
        case 'map':
          return <Map size={16} />;
        default:
          return null;
      }
    };

    const fontSize = (size?: string) => {
      switch (size) {
        case 'small':
          return TextVariant.ButtonSmall;
        case 'standard':
          return TextVariant.Button;
        default:
          return TextVariant.Button;
      }
    };

    useEffect(() => {
      window.addEventListener('mousedown', removeOutline);

      return () => window.removeEventListener('mousedown', removeOutline);
    }, []);

    useEffect(() => {
      window.addEventListener('keydown', addOutline);

      return () => window.removeEventListener('keydown', addOutline);
    }, []);

    return (
      <Container
        prefixIcon={prefixIcon}
        disabled={disabled}
        variant={variant}
        className={className}
      >
        <Link
          href={disabled ? '' : href}
          onClick={() => {
            if (!disabled) {
              onClick && onClick();
            }
          }}
          tabIndex={disabled ? '-1' : '0'}
          target={target}
          id={mouseDown ? 'mousedown' : ''}
          aria-disabled={disabled}
          ref={ref}
          {...rest}
        >
          <Text variant={fontSize(size)}>{text}</Text>
          {icon && renderIcon(icon)}
        </Link>
      </Container>
    );
  },
);

export default ButtonLink;

const Container = styled.div<{
  prefixIcon: boolean;
  disabled: boolean;
  variant: VariantType;
}>`
  display: flex;
  align-items: center;
  position: relative;
  width: fit-content;
  color: ${({ theme }) =>
    theme.typography
      ? theme.typography.defaultText
      : themeLight.typography.defaultText};

  &:hover {
    cursor: pointer;
  }
  &:hover,
  &:focus {
    a > span {
      border-color: transparent;
    }
    a > span,
    svg {
      color: ${({ theme }) =>
        theme.typography
          ? theme.typography.textLink
          : themeLight.typography.textLink};
    }

    ${({ variant }) =>
      variant === 'inverted' &&
      css`
        a > span,
        svg {
          color: ${({ theme }) =>
            theme.typography
              ? theme.typography.textLinkInverted
              : themeLight.typography.textLinkInverted};
          border-color: transparent;
        }
      `}
  }

  ${({ variant }) =>
    variant === 'primary' &&
    css`
      a > span,
      a:visited > span {
        border-color: ${({ theme }) =>
          theme.typography
            ? theme.typography.textLink
            : themeLight.typography.textLink};
      }
    `}

  ${({ variant }) =>
    variant === 'secondary' &&
    css`
      a > span,
      a:visited > span {
        border-color: ${({ theme }) =>
          theme.typography
            ? theme.typography.subtleText
            : themeLight.typography.subtleText};
      }
    `}
    
  ${({ variant }) =>
    variant === 'inverted' &&
    css`
      svg,
      a > span,
      a:visited > span {
        color: ${({ theme }) =>
          theme.typography
            ? theme.typography.defaultTextInverted
            : themeLight.typography.defaultTextInverted};
      }
      a > span,
      a:visited > span {
        border-color: ${({ theme }) =>
          theme.typography
            ? theme.typography.textLinkInverted
            : themeLight.typography.textLinkInverted};
      }
    `};

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
      svg,
      a > span,
      a:visited > span {
        color: ${({ theme }) =>
          theme.typography
            ? theme.typography.disabledText
            : themeLight.typography.disabledText};
      }
      a > span,
      a:visited > span {
        border-color: ${({ theme }) =>
          theme.typography
            ? theme.typography.disabledText
            : themeLight.typography.disabledText};
      }
    `}

  ${({ variant, disabled }) =>
    variant === 'inverted' &&
    disabled &&
    css`
      svg,
      a > span,
      a:visited > span {
        color: ${({ theme }) =>
          theme.typography
            ? theme.typography.disabledTextInverted
            : themeLight.typography.disabledTextInverted};
      }
      a > span,
      a:visited > span {
        border-color: ${({ theme }) =>
          theme.typography
            ? theme.typography.disabledTextInverted
            : themeLight.typography.disabledTextInverted};
      }
    `};

  svg {
    position: relative;
    left: ${spacing.space8};
    padding-bottom: ${spacing.space8};
    font-size: ${icons.s};
  }

  ${({ prefixIcon }) =>
    prefixIcon &&
    css`
      svg {
        position: absolute;
        left: 0;
      }
      a {
        margin-left: ${spacing.space24};
      }
    `}
`;

const Link = styled.a<any>`
  text-decoration: none;
  display: flex;
  align-items: center;

  > span {
    padding: 0 0 ${spacing.space8} 0;
    border-bottom: 2px solid;
    border-color: ${({ theme }) =>
      theme.typography
        ? theme.typography.textLink
        : themeLight.typography.textLink};
  }

  @media (max-width: ${breakpoints.s}) {
    font-size: 16px;
    line-height: 16px;
    padding-bottom: ${spacing.space4};
  }

  &#mousedown {
    outline: 0;
  }
`;

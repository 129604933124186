import React, { FC, ReactNode } from 'react';
import { ThemeProvider as StyledProvider } from 'styled-components';
import { themeLight } from '@naf/theme';

export interface ThemeProviderProps {
  children: ReactNode;
  theme?: typeof themeLight;
}

// Replace the values in defaultTheme with the same keys in the theme being passed
export const ThemeProvider: FC<ThemeProviderProps> = ({ children, theme = {} }) => {
  return <StyledProvider theme={{ ...themeLight, ...theme }}>{children}</StyledProvider>;
};

export default ThemeProvider;

import React, { createContext, useContext, forwardRef, ForwardRefExoticComponent, RefAttributes } from 'react';
import styled from 'styled-components';
import { breakpoints, spacing } from '@naf/theme';

type breakpointsType = {
  s: number;
  m: number;
  l: number;
  xl: number;
};

type adjustItem = 'start' | 'end' | 'center' | 'stretch';

/* Grid Col */

export interface GridColProps {
  /**
   * Set amount of columns per row for `breakpoints.s`.
   */
  s?: string;

  /**
   * Set amount of columns per row for `breakpoints.m`.
   */
  m?: string;

  /**
   * Set amount of columns per row for `breakpoints.l`.
   */
  l?: string;

  /**
   * Set amount of columns per row for `breakpoints.xl`.
   */
  xl?: string;

  /**
   * Adjust the element horizontally in the available space in the cell.
   */
  justify?: {
    s: adjustItem;
    m: adjustItem;
    l: adjustItem;
    xl: adjustItem;
  };

  /**
   * Adjust the element vertically in the available space in the cell.
   */
  align?: {
    s: adjustItem;
    m: adjustItem;
    l: adjustItem;
    xl: adjustItem;
  };

  children: any;
  className?: string;
  [rest: string]: any;
}

export const GridCol: ForwardRefExoticComponent<GridColProps & RefAttributes<HTMLDivElement>> = forwardRef<
  HTMLDivElement,
  GridColProps
>(({ s = '12', m = '6', l = '4', xl = '3', justify, align, children, className, ...rest }: GridColProps, ref) => {
  const breakpoints = useContext(GridContext);

  return (
    <Col
      s={s}
      m={m}
      l={l}
      xl={xl}
      justify={justify}
      align={align}
      className={className}
      breakpoints={breakpoints}
      ref={ref}
      {...rest}
    >
      {children}
    </Col>
  );
});

const Col = styled.div<any>`
  @media (max-width: ${(props) => (props.breakpoints ? props.breakpoints.s : breakpoints.s)}px) {
    grid-column: auto / span ${(props) => props.s};
    justify-self: ${(props) => props.justify && props.justify.s};
    align-self: ${(props) => props.align && props.align.s};
  }

  @media (min-width: ${(props) => (props.breakpoints ? props.breakpoints.s : breakpoints.s)}px) {
    grid-column: auto / span ${(props) => props.m};
    justify-self: ${(props) => props.justify && props.justify.m};
    align-self: ${(props) => props.align && props.align.m};
  }

  @media (min-width: ${(props) => (props.breakpoints ? props.breakpoints.m : breakpoints.m)}px) {
    grid-column: auto / span ${(props) => props.l};
    justify-self: ${(props) => props.justify && props.justify.l};
    align-self: ${(props) => props.align && props.align.l};
  }

  @media (min-width: ${(props) => (props.breakpoints ? props.breakpoints.l : breakpoints.l)}px) {
    grid-column: auto / span ${(props) => props.xl};
    justify-self: ${(props) => props.justify && props.justify.xl};
    align-self: ${(props) => props.align && props.align.xl};
  }
`;

/* Grid Row */

export interface GridRowProps {
  /**
   * Antall kolonner i grid'en. Default er 12.
   */
  columns?: number;
  /**
   * Justerer elementer i `Grid` horisontalt i enkeltceller.
   */
  justifyItems?: {
    s: adjustItem;
    m: adjustItem;
    l: adjustItem;
    xl: adjustItem;
  };
  /**
   * Justerer elementer i `Grid` vertikalt i enkeltceller.
   */
  alignItems?: {
    s: adjustItem;
    m: adjustItem;
    l: adjustItem;
    xl: adjustItem;
  };
  children: any;
  className?: string;
  [rest: string]: any;
}

export const GridRow: ForwardRefExoticComponent<GridRowProps & RefAttributes<HTMLDivElement>> = forwardRef<
  HTMLDivElement,
  GridRowProps
>(({ columns = 12, justifyItems, alignItems, className, children, ...rest }: GridRowProps, ref) => {
  const breakpoints = useContext(GridContext);

  return (
    <Row
      columns={columns}
      justifyItems={justifyItems}
      alignItems={alignItems}
      className={className}
      breakpoints={breakpoints}
      ref={ref}
      {...rest}
    >
      {children}
    </Row>
  );
});

const Row = styled.div<any>`
  /* Row */
  grid-column: 1 / -1;
  grid-row: auto;

  /* Subgrid */
  display: grid;
  grid-template-columns: repeat(${(props) => props.columns}, 1fr);
  grid-template-rows: auto;
  grid-gap: ${spacing.space24};

  @media (max-width: ${(props) => (props.breakpoints ? props.breakpoints.s : breakpoints.s)}px) {
    justify-items: ${(props) => props.justifyItems && props.justifyItems.s};
    align-items: ${(props) => props.alignItems && props.alignItems.s};
  }

  @media (min-width: ${(props) => (props.breakpoints ? props.breakpoints.s : breakpoints.s)}px) {
    justify-items: ${(props) => props.justifyItems && props.justifyItems.m};
    align-items: ${(props) => props.alignItems && props.alignItems.m};
  }

  @media (min-width: ${(props) => (props.breakpoints ? props.breakpoints.m : breakpoints.m)}px) {
    justify-items: ${(props) => props.justifyItems && props.justifyItems.l};
    align-items: ${(props) => props.alignItems && props.alignItems.l};
  }

  @media (min-width: ${(props) => (props.breakpoints ? props.breakpoints.l : breakpoints.l)}px) {
    justify-items: ${(props) => props.justifyItems && props.justifyItems.xl};
    align-items: ${(props) => props.alignItems && props.alignItems.xl};
  }
`;

/* Grid */

const defaultBreakpoints = {
  s: parseInt(breakpoints.s.replace('px', '')),
  m: parseInt(breakpoints.m.replace('px', '')),
  l: parseInt(breakpoints.l.replace('px', '')),
  xl: parseInt(breakpoints.xl.replace('px', '')),
};

const GridContext = createContext(defaultBreakpoints);

export interface GridProps {
  /**
   * Setter custom brekkpunkter for kolonnene i `Grid`.
   */
  breakpoints?: breakpointsType;
  /**
   * Antall kolonner i grid'en. Default er 12.
   */
  columns?: number;
  /**
   * Justerer elementer i `Grid` horisontalt i enkeltceller.
   */
  justifyItems?: {
    s: adjustItem;
    m: adjustItem;
    l: adjustItem;
    xl: adjustItem;
  };
  /**
   * Justerer elementer i `Grid` vertikalt i enkeltceller.
   */
  alignItems?: {
    s: adjustItem;
    m: adjustItem;
    l: adjustItem;
    xl: adjustItem;
  };
  children: any;
  className?: string;
  [rest: string]: any;
}

export const Grid: ForwardRefExoticComponent<GridProps & RefAttributes<HTMLDivElement>> = forwardRef<
  HTMLDivElement,
  GridProps
>(
  (
    {
      breakpoints = defaultBreakpoints,
      columns = 12,
      justifyItems,
      alignItems,
      children,
      className,
      ...rest
    }: GridProps,
    ref,
  ) => {
    const { s, m, l, xl } = breakpoints;

    return (
      <GridContext.Provider value={breakpoints}>
        <Container
          breakpoints={{ s: s, m: m, l: l, xl: xl }}
          columns={columns}
          justifyItems={justifyItems}
          alignItems={alignItems}
          className={className}
          ref={ref}
          {...rest}
        >
          {children}
        </Container>
      </GridContext.Provider>
    );
  },
);

const Container = styled.div<any>`
  display: grid;
  grid-template-columns: repeat(${(props) => props.columns}, 1fr);
  grid-template-rows: auto;
  grid-gap: ${spacing.space24};

  @media (max-width: ${(props) => (props.breakpoints ? props.breakpoints.s : breakpoints.s)}px) {
    grid-gap: ${spacing.space24};
    justify-items: ${(props) => props.justifyItems && props.justifyItems.s};
    align-items: ${(props) => props.alignItems && props.alignItems.s};

    ${Row} {
      grid-gap: ${spacing.space24};
    }
  }

  @media (min-width: ${(props) => (props.breakpoints ? props.breakpoints.s : breakpoints.s)}px) {
    grid-gap: ${spacing.space24};
    justify-items: ${(props) => props.justifyItems && props.justifyItems.m};
    align-items: ${(props) => props.alignItems && props.alignItems.m};

    ${Row} {
      grid-gap: ${spacing.space24};
    }
  }

  @media (min-width: ${(props) => (props.breakpoints ? props.breakpoints.m : breakpoints.m)}px) {
    grid-gap: ${spacing.space32};
    justify-items: ${(props) => props.justifyItems && props.justifyItems.l};
    align-items: ${(props) => props.alignItems && props.alignItems.l};

    ${Row} {
      grid-gap: ${spacing.space32};
    }
  }

  @media (min-width: ${(props) => (props.breakpoints ? props.breakpoints.l : breakpoints.l)}px) {
    grid-gap: ${spacing.space32};
    justify-items: ${(props) => props.justifyItems && props.justifyItems.xl};
    align-items: ${(props) => props.alignItems && props.alignItems.xl};

    ${Row} {
      grid-gap: ${spacing.space32};
    }
  }
`;

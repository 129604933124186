import React, { useEffect, useState, useRef, useCallback, FC } from 'react';
import styled, { css } from 'styled-components';
import { fontStyle, spacing, breakpoints, themeLight } from '@naf/theme';
import { nafColor } from '@nafcore/theme';

export interface TableProps {
  /**
   * Set max-width on table container.
   */
  maxWidth?: string;

  /**
   * Set min-width on table container.
   */
  minWidth?: string;

  /**
   * Set the orientation of the table header.
   */
  variant?: 'horizontal' | 'vertical';

  /**
   * Content inside table.
   */
  children: any;

  /**
   * Optional classname.
   */
  className?: string;
}

// Table
export const Table: FC<TableProps> = ({
  children,
  maxWidth,
  minWidth = '340px',
  variant = 'horizontal',
  className,
}: TableProps) => {
  const outerRef = useRef();
  const tableRef = useRef();
  const [hasOverflowRight, setHasOverflowRight] = useState(false);
  const [hasOverflowLeft, setHasOverflowLeft] = useState(false);

  const checkOverflow = useCallback(() => {
    const outer = (outerRef as any).current;

    if (outer.scrollLeft > 0) {
      setHasOverflowLeft(true);
    } else {
      setHasOverflowLeft(false);
    }
    if (outer.scrollLeft < outer.scrollWidth - outer.clientWidth) {
      setHasOverflowRight(true);
    } else {
      setHasOverflowRight(false);
    }
  }, []);

  useEffect(() => {
    checkOverflow();
  }, [checkOverflow]);

  useEffect(() => {
    window.addEventListener('resize', checkOverflow);
    return () => window.removeEventListener('resize', checkOverflow);
  }, [checkOverflow]);

  useEffect(() => {
    const outer = (outerRef as any).current;
    outer.addEventListener('scroll', checkOverflow);
    return () => outer.removeEventListener('scroll', checkOverflow);
  }, [checkOverflow]);

  return (
    <Wrap maxWidth={maxWidth} className={className}>
      <Outer ref={outerRef} hasOverflowLeft={hasOverflowLeft} hasOverflowRight={hasOverflowRight}>
        <TableContainer ref={tableRef} variant={variant} minWidth={minWidth}>
          {children}
        </TableContainer>
        <OverflowIndicator left visible={hasOverflowLeft} />
        <OverflowIndicator right visible={hasOverflowRight} />
      </Outer>
    </Wrap>
  );
};

// TableRow
export interface TableRowProps {
  /**
   * Content inside table row.
   */
  children: any;

  /**
   * Function that is called when the component is clicked.
   */
  onClick?: () => void;
}
export const TableRow: FC<TableRowProps> = ({ children, onClick }: TableRowProps) => (
  <Tr onClick={onClick}>{children}</Tr>
);

// TableHead
export const TableHead: FC<any> = ({ children }: any) => <THead>{children}</THead>;

// TableBody
export const TableBody: FC<any> = ({ children }: any) => <TBody>{children}</TBody>;

// TableCell
export interface TableCellProps {
  /**
   * Content inside table cell.
   */
  children: any;

  /**
   * Set the formatting of the cell to header or data.
   */
  as?: 'th' | 'td';

  /**
   * Set the alignment of the cell to left, right, or center.
   */
  align?: 'left' | 'right' | 'center';
}
export const TableCell: FC<TableCellProps> = ({ children, as = 'td', align }: TableCellProps) => (
  <Td as={as} align={align}>
    {children}
  </Td>
);

export default Table;

const Wrap = styled.div<any>`
  position: relative;

  ${({ maxWidth }) =>
    maxWidth &&
    css`
      max-width: ${maxWidth};
    `}
`;

const Outer = styled.div<any>`
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
`;

const TableContainer = styled.table<any>`
  width: 100%;
  display: table;
  table-layout: fixed;
  border-spacing: 0;
  border-collapse: collapse;
  min-width: ${(props) => props.minWidth};
`;

const OverflowIndicator = styled.div<any>`
  position: absolute;
  bottom: 0;
  right: 0;
  width: ${spacing.space80};
  z-index: 2;
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  transition: opacity 0.3s ease;
  pointer-events: none;
  height: 100%;

  ${({ left }) =>
    left &&
    css`
      left: 0;
      background: linear-gradient(to right, #fff 1%, rgba(255, 255, 255, 0) 100%);
    `}

  ${({ right }) =>
    right &&
    css`
      right: 0;
      background: linear-gradient(to left, #fff 1%, rgba(255, 255, 255, 0) 100%);
    `}
`;

const Tr = styled.tr<any>`
  display: table-row;
  box-sizing: border-box;

  ${({ onClick }) =>
    onClick &&
    css`
      &:hover {
        cursor: pointer;
        background-color: ${nafColor.signature.yellow10};
      }
    `}
`;

const THead = styled.thead`
  display: table-header-group;

  tr {
    border-bottom: 1px solid;
    border-color: ${({ theme }) => (theme.border ? theme.border.default : themeLight.border.default)};
  }
`;

const TBody = styled.tbody`
  display: table-row-group;

  tr:not(:last-child) {
    border-bottom: 1px solid;
    border-color: ${({ theme }) => (theme.border ? theme.border.subtle : themeLight.border.subtle)};
  }
`;

const Td = styled.td<{ as: 'th' | 'td'; align?: 'left' | 'right' | 'center' }>`
  display: table-cell;
  padding: ${spacing.space16} 0;
  text-align: left;
  ${({ align }) => `text-align: ${align}` || 'left'};
  box-sizing: border-box;

  ${({ as }) =>
    as === 'td' &&
    css`
      ${fontStyle.article.articleText};

      @media (max-width: ${breakpoints.s}) {
        ${fontStyle.bodyText.bodyText};
      }
    `}

  ${({ as }) =>
    as === 'th' &&
    css`
      ${fontStyle.article.articleTextHeader};

      @media (max-width: ${breakpoints.s}) {
        ${fontStyle.bodyText.bodyTextHeader};
      }
    `}
`;

import { css } from 'styled-components';
import { fontStyle, themeLight } from '@naf/theme';

export default css`
  html {
    ${fontStyle.article.articleText};
    ${fontStyle.html};
    font-family: ${({ theme }) =>
      theme.fontFamilies
        ? theme.fontFamilies.default
        : themeLight.fontFamilies.default}, -system-ui, system-ui, sans-serif;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-family: ${({ theme }) =>
      theme.fontFamilies
        ? theme.fontFamilies.headers
        : themeLight.fontFamilies.headers}, -system-ui, system-ui, sans-serif;
    }
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-variant-numeric: lining-nums;
    font-feature-settings: 'lnum';
    /* vendor-prefixes */
    -moz-font-feature-settings: 'lnum=1';
    -ms-font-feature-settings: 'lnum';
    -webkit-font-feature-settings: 'lnum';
    -o-font-feature-settings: 'lnum';
  }
`;

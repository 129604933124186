import React, { useState, useEffect, forwardRef, ForwardRefExoticComponent, RefAttributes } from 'react';
import styled, { css } from 'styled-components';
import { spacing, themeLight, radius } from '@naf/theme';
import { Text, TextVariant } from '@naf/text';
import { Check, Remove } from '@styled-icons/material';

export interface CheckProps {
  /**
   * Laster inn knappen i `checked` state.
   */
  checked?: boolean;
  /**
   * Funksjon når sjekkboksen trykkes.
   */
  onChange?: (payload: any) => void;
  /**
   * Navn for å gruppere sjekkbokser som hører sammen. Vil ikke rendres på siden.
   */
  name: string;
  /**
   * Tekst som vises med sjekkboksen.
   */
  label?: string;
  /**
   * Størrelse på labelteksten.
   */
  labelSize?: TextVariant.ArticleText | TextVariant.BodyText | TextVariant.ArticleTextHeader;
  /**
   * En liten informasjonstekst plassert under label. Brukes f.eks for feilmeldinger.
   */
  message?: string;
  /**
   * Brukes for å vise en feilstatus. Endrer sjekkboksens styling.
   */
  error?: boolean;
  /**
   * Setter sjekkboksen som inaktiv. Endrer sjekkboksens styling.
   */
  disabled?: boolean;
  /**
   * Setter sjekkboksens ikon til `indeterminate` state.
   */
  indeterminate?: boolean;
  /**
   * Andre props man vil sende til `Checkbox`.
   */
  [rest: string]: any;
  className?: string;
}

export const Checkbox: ForwardRefExoticComponent<CheckProps & RefAttributes<HTMLDivElement>> = forwardRef<
  HTMLDivElement,
  CheckProps
>(
  (
    {
      checked = false,
      onChange,
      name,
      label,
      labelSize = TextVariant.ArticleText,
      message,
      error = false,
      disabled = false,
      indeterminate = false,
      className,
      ...rest
    }: CheckProps,
    ref,
  ) => {
    const [isChecked, setIsChecked] = useState(checked);
    const [mouseDown, setMouseDown] = useState(false);
    useEffect(() => {
      setIsChecked(checked);
    }, [checked]);

    const handleChange = (e: any) => {
      setIsChecked(!isChecked);
      if (onChange) {
        onChange({ name: name, value: e.target.checked });
      }
    };

    const removeOutline = () => {
      setMouseDown(true);
    };

    const addOutline = (e: KeyboardEvent) => {
      if (e.key === 'Tab') {
        setMouseDown(false);
      }
    };

    useEffect(() => {
      document.addEventListener('mousedown', removeOutline);

      return () => document.removeEventListener('mousedown', removeOutline);
    }, []);

    useEffect(() => {
      document.addEventListener('keydown', addOutline);

      return () => document.removeEventListener('keydown', addOutline);
    }, []);

    return (
      <Container className={className} id={mouseDown ? 'mousedown' : ''}>
        <CheckboxWrap disabled={disabled}>
          <ButtonWrap>
            <PseudoCheckbox
              name={name}
              checked={isChecked}
              error={error}
              disabled={disabled}
              onClick={handleChange}
              aria-label="Checkbox"
              ref={ref}
              type="button"
              {...rest}
            >
              {indeterminate ? <Remove /> : <Check />}
            </PseudoCheckbox>
          </ButtonWrap>
          <Label variant={labelSize} disabled={disabled} onClick={!disabled ? handleChange : null}>
            {label}
          </Label>
        </CheckboxWrap>
        {message && (
          <Message variant={TextVariant.Small} error={error}>
            {message}
          </Message>
        )}
      </Container>
    );
  },
);

export default Checkbox;

const Container = styled.div`
  display: inline-flex;
  flex-direction: column;

  &#mousedown *:focus {
    outline: 0;
  }
`;

const ButtonWrap = styled.div`
  display: inline-flex;
  width: fit-content;
  align-items: center;
`;

const PseudoCheckbox = styled.button<any>`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 0;
  min-width: ${spacing.space20};
  width: ${spacing.space20};
  height: ${spacing.space20};
  border-radius: ${radius.s};
  box-sizing: border-box;
  transition: background 0.2 ease;
  border: 2px solid;
  border-color: ${({ theme }) => (theme.border ? theme.border.heavy : themeLight.border.heavy)};
  background: ${({ theme }) => (theme.background ? theme.background.default : themeLight.background.default)};
  color: ${({ theme }) => (theme.typography ? theme.typography.defaultText : themeLight.typography.defaultText)};

  svg {
    position: absolute;
    display: ${({ checked }) => (checked ? 'initial' : 'none')};
    font-size: ${spacing.space20};
  }

  &:hover {
    cursor: pointer;
  }

  ${({ checked }) =>
    checked &&
    css`
      border-color: ${({ theme }) =>
        theme.componentColors
          ? theme.componentColors.interactiveElement.active
          : themeLight.componentColors.interactiveElement.active};
      background: ${({ theme }) =>
        theme.componentColors
          ? theme.componentColors.interactiveElement.active
          : themeLight.componentColors.interactiveElement.active};
    `}

  ${({ error }) =>
    error &&
    css`
      border-color: ${({ theme }) =>
        theme.componentColors ? theme.componentColors.alert.error : themeLight.componentColors.alert.error};
      background: ${({ theme }) => (theme.background ? theme.background.default : themeLight.background.default)};
      color: ${({ theme }) =>
        theme.componentColors
          ? theme.componentColors.alert.errorBackground
          : themeLight.componentColors.alert.errorBackground};
    `}

  ${({ error, checked }) =>
    error &&
    checked &&
    css`
      border-color: ${({ theme }) =>
        theme.componentColors ? theme.componentColors.alert.error : themeLight.componentColors.alert.error};
      background: ${({ theme }) =>
        theme.componentColors ? theme.componentColors.alert.error : themeLight.componentColors.alert.error};
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      border-color: ${({ theme }) => (theme.border ? theme.border.default : themeLight.border.default)};
      background: ${({ theme }) => (theme.background ? theme.background.default : themeLight.background.default)};
      color: ${({ theme }) => (theme.typography ? theme.typography.disabledText : themeLight.typography.disabledText)};

      &:hover {
        cursor: not-allowed;
        border-color: ${({ theme }) => (theme.border ? theme.border.default : themeLight.border.default)};
      }
    `}

  ${({ disabled, checked }) =>
    disabled &&
    checked &&
    css`
      border-color: ${({ theme }) => (theme.border ? theme.border.default : themeLight.border.default)};
      background: ${({ theme }) =>
        theme.componentColors
          ? theme.componentColors.interactiveElement.disabled
          : themeLight.componentColors.interactiveElement.disabled};
    `}
`;

const Label = styled(Text)<{ onClick: any; disabled: boolean }>`
  padding-left: ${spacing.space8};
  margin: 0;
  color: ${({ theme }) => (theme.typography ? theme.typography.defaultText : themeLight.typography.defaultText)};

  &:hover {
    cursor: pointer;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      color: ${({ theme }) => (theme.typography ? theme.typography.disabledText : themeLight.typography.disabledText)};

      &:hover {
        cursor: not-allowed;
      }
    `}
`;

const Message = styled(Text)<{ error: boolean }>`
  margin: 0;
  margin-left: calc(${spacing.space20} + ${spacing.space8});

  color: ${({ theme }) => (theme.typography ? theme.typography.defaultText : themeLight.typography.defaultText)};

  ${({ error }) =>
    error &&
    css`
      color: ${({ theme }) =>
        theme.componentColors ? theme.componentColors.alert.error : themeLight.componentColors.alert.error};
    `}
`;

const CheckboxWrap = styled.div<{ disabled: boolean }>`
  display: inline-flex;
  align-items: center;
  width: fit-content;
  height: fit-content;

  ${({ disabled }) =>
    !disabled &&
    css`
      &:hover {
        ${PseudoCheckbox} {
          border-color: ${({ theme }) => (theme.border ? theme.border.default : themeLight.border.default)};
        }
      }
    `}
`;

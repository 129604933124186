import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { fontStyle, spacing, themeLight } from '@naf/theme';
import { Error } from '@styled-icons/material/Error';

export interface MessageProps {
  children: any;

  /**
   * Optional classname.
   */
  className?: string;

  /**
   * Used to illustrate an error state.
   */
  error?: boolean;

  /**
   * Set the message field as inactive and changes styling.
   */
  disabled?: boolean;

  [rest: string]: any;
}

export const Message: FC<MessageProps> = ({
  children,
  className,
  error = false,
  disabled = false,
  ...rest
}: MessageProps) => {
  return (
    <MessageWrap className={className} {...rest} error={error} disabled={disabled}>
      <Error size={16} />
      {children}
    </MessageWrap>
  );
};

export default Message;

const MessageWrap = styled.div<any>`
  ${fontStyle.bodyText.small};
  display: flex;
  align-items: center;
  margin-top: ${spacing.space8};

  svg {
    margin-right: ${spacing.space8};
  }

  ${({ error }) =>
    error &&
    css`
      color: ${({ theme }) =>
        theme.componentColors ? theme.componentColors.alert.error : themeLight.componentColors.alert.error};
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      color: ${({ theme }) => (theme.typography ? theme.typography.disabledText : themeLight.typography.disabledText)};
    `}
`;

import React, { useCallback, useState, useRef, useEffect, FC } from 'react';
import styled from 'styled-components';
import { spacing, icons, radius, themeLight, breakpoints } from '@naf/theme';
import { Text, TextVariant } from '@naf/text';
import { HelpOutline } from '@styled-icons/material-outlined/HelpOutline';
import { Help } from '@styled-icons/material/Help';

export interface TooltipProps {
  /**
   * Tekst som vises i tooltip boksen.
   */
  text: string;
  className?: string;
}

export const Tooltip: FC<TooltipProps> = ({ text, className }: TooltipProps) => {
  const [tooltipPos, setTooltipPos] = useState(0);
  const [isTooltipRightEdge, setIsTooltipRightEdge] = useState(false);
  const [isHover, toggleHover] = useState(false);
  const [isSelected, toggleSelected] = useState(false);
  const iconRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!!iconRef.current) {
      if (typeof window !== 'undefined' && window.innerWidth < parseInt(breakpoints.m, 10)) {
        const rect = iconRef.current.getBoundingClientRect();
        const vpRightArea = window.innerWidth - (window.innerWidth / 3);
        setIsTooltipRightEdge(rect?.right > vpRightArea);
      }
      setTooltipPos(iconRef.current.offsetLeft);
    }
  }, []);

  const Icon = useCallback(() => {
    if (isSelected) {
      return <Help size={24} />;
    } else if (isHover) {
      return <Help size={24} />;
    } else {
      return <HelpOutline size={24} />;
    }
  }, [isSelected, isHover]);

  return (
    <Container>
      <TooltipWrapper
        className={className}
        tabIndex={0}
        ref={iconRef}
        onMouseEnter={() => toggleHover(true)}
        onMouseLeave={() => toggleHover(false)}
        onFocus={() => toggleSelected(true)}
        onBlur={() => toggleSelected(false)}
      >
        {Icon()}
        <InfoBox tooltipPos={tooltipPos} isCloseToRightEdge={isTooltipRightEdge}>
          <StyledText variant={TextVariant.Small}>{text}</StyledText>
        </InfoBox>
      </TooltipWrapper>
    </Container>
  );
};

export default Tooltip;

const Container = styled.div`
  display: inline-flex;
  flex-basis: 100%;
  margin: 0;
  position: relative;
`;

const TooltipWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  color: ${({ theme }) =>
    theme.typography ? theme.typography.defaultTextInverted : themeLight.typography.defaultTextInverted};

  span {
    display: none;
  }

  svg {
    max-width: ${icons.m};
    max-height: ${icons.m};
    color: ${({ theme }) => (theme.background ? theme.background.brandInverted : themeLight.background.brandInverted)};
  }

  &:hover,
  &:focus {
    span {
      display: block;
    }
  }
`;

const InfoBox = styled.span<{ tooltipPos: number, isCloseToRightEdge?: boolean }>`
  position: absolute;
  left: 0;
  bottom: ${spacing.space32};
  z-index: 99;
  padding: ${spacing.space8} ${spacing.space12};
  min-width: calc(${(props) => props.tooltipPos}px + ${spacing.space12});
  border-radius: ${radius.s};
  background: ${({ theme }) =>
    theme.background ? theme.background.brandInverted : themeLight.background.brandInverted};
  
  &:after {
    content: ' ';
    position: absolute;
    top: 100%;
    left: calc(${(props) => props.tooltipPos}px + 0.5 * ${spacing.space20});
    margin-left: -3px;
    border: 6px solid;
    border-color: ${({ theme }) =>
        theme.background ? theme.background.brandInverted : themeLight.background.brandInverted}
      transparent transparent transparent;
  }
  
  @media (max-width: ${breakpoints.m}) {
    left: ${(props) => (props.isCloseToRightEdge ? `auto` : `0`)};
    right: ${(props) => (props.isCloseToRightEdge ? `0` : `auto`)};

    &:after {
    ${(props) => (props.isCloseToRightEdge && `right: calc(${props.tooltipPos}px + 0.5 * ${spacing.space20}); left: auto;`)};
    }
  }
`;

const StyledText = styled(Text)`
  margin: 0;
`;

import React, { forwardRef, ForwardRefExoticComponent, RefAttributes, FC } from 'react';
import styled from 'styled-components';
import { spacing } from '@naf/theme';
import { Label } from './label';
import { Input } from './input';

/** Date input wrapper */

export interface WrapperProps {
  children: any;
  className?: string;
  [rest: string]: any;
}

export const DateWrapper: FC<WrapperProps> = ({ children, className, ...rest }: WrapperProps) => {
  return (
    <Container className={className} {...rest}>
      {children}
    </Container>
  );
};

const Container = styled.form`
  > div:not(:last-child) {
    margin-right: ${spacing.space16};
  }

  input {
    text-align: center;
  }
`;

/** Date field wrapper */

export interface FieldProps {
  children: any;
  className?: string;
  [rest: string]: any;
}

export const DateField: FC<FieldProps> = ({ children, className, ...rest }: FieldProps) => {
  return (
    <DateInput className={className} {...rest}>
      {children}
    </DateInput>
  );
};

const DateInput = styled.div`
  display: inline-flex;
  flex-flow: column wrap;
`;

/** Day input */

export interface DayProps {
  /**
   * Function that is called when the user types in information.
   */
  onChange?: (val: number) => void;

  /**
   * Unique ID to handle automatic focus when the field is filled out.
   */
  id?: string;

  /**
   * ID for next field that should get focus after the user has filled in the necessary information.
   */
  nextId?: string;

  className?: string;
  [rest: string]: any;
}

export const Day: ForwardRefExoticComponent<DayProps & RefAttributes<HTMLDivElement>> = forwardRef<
  HTMLDivElement,
  DayProps
>(({ onChange, id, nextId, className, ...rest }: DayProps, ref) => {
  const handleChange = (e: any) => {
    if (!!onChange) onChange(e);
    if (e.length >= 2) {
      if (!!nextId) {
        // @ts-ignore-next
        document.querySelector(`input[id='${nextId}']`).focus();
      }
      return;
    }
  };

  return (
    <DayInput className={className}>
      <Label width={70}>Dato</Label>
      <Input
        ref={ref}
        name="day"
        id={id}
        onChange={(e: any) => handleChange(e)}
        placeholder="dd"
        width={70}
        pattern="[1-9]|[0-2][0-9]|[3][01]"
        maxLength={2}
        {...rest}
      />
    </DayInput>
  );
});

const DayInput = styled.div``;

/** Month input */

export interface MonthProps {
  /**
   * Funksjon som kalles når brukeren skriver inn informasjon.
   */
  onChange?: (val: number) => void;
  /**
   * Unik ID for å håndtere automatisk fokus ved ferdig utfylt felt.
   */
  id?: string;
  /**
   * ID for neste felt som skal få fokus etter brukeren har fylt inn nødvendig informasjon.
   */
  nextId?: string;
  className?: string;
  [rest: string]: any;
}

export const Month: ForwardRefExoticComponent<MonthProps & RefAttributes<HTMLDivElement>> = forwardRef<
  HTMLDivElement,
  MonthProps
>(({ onChange, id, nextId, className, ...rest }: MonthProps, ref) => {
  const handleChange = (e: any) => {
    if (!!onChange) onChange(e);
    if (e.length >= 2) {
      if (!!nextId) {
        // @ts-ignore-next
        document.querySelector(`input[id='${nextId}']`).focus();
      }
      return;
    }
  };

  return (
    <MonthInput className={className}>
      <Label width={70}>Måned</Label>
      <Input
        ref={ref}
        id={id}
        name="month"
        onChange={(e: any) => handleChange(e)}
        placeholder="mm"
        width={70}
        pattern="[1-9]|[0][0-9]|[1][0-2]"
        maxLength="2"
        {...rest}
      />
    </MonthInput>
  );
});

const MonthInput = styled.div``;

/** Year input */

export interface YearProps {
  /**
   * Funksjon som kalles når brukeren skriver inn informasjon.
   */
  onChange?: (val: number) => void;
  /**
   * Unik ID for å håndtere automatisk fokus ved ferdig utfylt felt.
   */
  id?: string;
  /**
   * ID for neste felt som skal få fokus etter brukeren har fylt inn nødvendig informasjon.
   */
  nextId?: string;
  className?: string;
  [rest: string]: any;
}

export const Year: ForwardRefExoticComponent<YearProps & RefAttributes<HTMLDivElement>> = forwardRef<
  HTMLDivElement,
  YearProps
>(({ onChange, id, nextId, className, ...rest }: YearProps, ref) => {
  const handleChange = (e: any) => {
    if (!!onChange) onChange(e);
    if (e.length >= 4) {
      if (!!nextId) {
        // @ts-ignore-next
        document.querySelector(`input[id='${nextId}']`).focus();
      }
      return;
    }
  };

  return (
    <YearInput className={className}>
      <Label width={100}>År</Label>
      <Input
        ref={ref}
        name="year"
        id={id}
        onChange={(e: any) => handleChange(e)}
        placeholder="åååå"
        width={100}
        pattern="[12][0-9]{3}"
        maxLength="4"
        {...rest}
      />
    </YearInput>
  );
});

const YearInput = styled.div``;

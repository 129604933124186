import { MobileFontStyles } from './fontTypes';

export const mobileFontStyles: MobileFontStyles = {
  headers: {
    display: {
      'font-size': '2.5rem',
      'line-height': '3rem',
      'font-weight': '900',
      'letter-spacing': '0rem',
      'font-style': 'normal',
    },
    giga: {
      'font-size': 'clamp(2.5rem, 10vw, 7rem)',
      'line-height': '3rem',
      'font-style': 'normal',
      'font-weight': '900',
      'letter-spacing': '0rem',
    },
    header1: {
      'font-size': '2rem',
      'line-height': '2.5rem',
      'font-weight': '900',
      'letter-spacing': '0rem',
      'font-style': 'normal',
    },
    header2: {
      'font-size': '1.5rem',
      'line-height': '2rem',
      'font-weight': '900',
      'letter-spacing': '0rem',
      'font-style': 'normal',
    },
    header3: {
      'font-size': '1.25rem',
      'line-height': '1.75rem',
      'font-weight': '700',
      'letter-spacing': '0rem',
      'font-style': 'normal',
    },
    subHeader: {
      'font-size': '1.25rem',
      'line-height': '1.75rem',
      'font-weight': '500',
      'letter-spacing': '0rem',
      'font-style': 'normal',
    },
  },
  article: {
    ingress: {
      'font-size': '1.25rem',
      'line-height': '2rem',
      'font-weight': '300',
      'letter-spacing': '0rem',
      'font-style': 'italic',
    },
    blockquote: {
      'font-size': '1.5rem',
      'line-height': '2rem',
      'font-weight': '300',
      'letter-spacing': '0rem',
      'font-style': 'italic',
    },
    blockquoteLarge: {
      'font-size': '1.75rem',
      'line-height': '2.5rem',
      'font-weight': '300',
      'letter-spacing': '0rem',
      'font-style': 'italic',
    },
  },
  componentText: {
    cardHeader: {
      'font-size': '1.125rem',
      'line-height': '1.5rem',
      'font-weight': '700',
      'letter-spacing': '0rem',
      'font-style': 'normal',
    },
  },
};

import {
  createGlobalStyle,
  ThemedStyledComponentsModule,
} from 'styled-components';
import stylesheet from './stylesheet';

const styled = {
  createGlobalStyle,
} as ThemedStyledComponentsModule<any>;

export const GlobalStyle = styled.createGlobalStyle`
  ${stylesheet}
`;

export default GlobalStyle;

import React, { useState, useEffect, useCallback, useRef, FC } from 'react';
import styled, { css } from 'styled-components';
import { ErrorOutline } from '@styled-icons/material-outlined/ErrorOutline';
import { CheckCircleOutline } from '@styled-icons/material-outlined/CheckCircleOutline';
import { Close } from '@styled-icons/material/Close';
import { Text, TextVariant } from '@naf/text';
import { spacing, radius, themeLight } from '@naf/theme';

export enum ToastVariant {
  Information = 'information',
  Success = 'success',
  Error = 'error',
}

export interface ToastProps {
  /**
   * Setter toastens styling. Henter farger fra NAF's designguide.
   */
  variant?: ToastVariant;

  /**
   * Skal det vises et informativt ikon sammen med toastens innhold?
   */
  icon?: boolean;

  /**
   * Setter en klikkbar funksjonalitet på toasten. Vil overstyre `timeOut` og hindre toasten fra å lukkes før funksjonen er utført.
   */
  action?: {
    onClick: () => void;
    text: string;
  };

  /**
   * Setter et lukkeikon på toasten. Vil overstyre `timeOut` og hindre toasten fra å lukkes før ikonet trykkes.
   */
  closable?: boolean;

  /**
   * Hvor lenge toasten skal være synlig i ms. Kan settes til `false` dersom toasten ikke skal lukkes automatisk.
   */
  timeOut?: boolean | number;

  /**
   * Toastens innhold.
   */
  message: any;
}

export const useToast = (): {
  showToast: (props: ToastProps) => void;
  closeToast: () => void;
  Toast: FC<any>;
} => {
  const [visible, setVisible] = useState<boolean>(false);
  const [variant, setVariant] = useState<ToastProps['variant']>(ToastVariant.Information);
  const [icon, setIcon] = useState<ToastProps['icon']>(false);
  const [action, setAction] = useState<ToastProps['action']>();
  const [closable, setClosable] = useState<ToastProps['closable']>(false);
  const [timeOut, setTimeout] = useState<ToastProps['timeOut']>(5000);
  const [message, setMessage] = useState<ToastProps['message']>();

  const Icon = ({ iconVariant }: { iconVariant?: ToastVariant }) => {
    if (iconVariant === 'success') {
      return <CheckCircleOutline size={24} />;
    }
    return <ErrorOutline size={24} />;
  };

  const closeToast = () => {
    setVisible(false);
  };

  const showToast = useCallback(
    ({ variant, icon, action, closable, timeOut, message }: ToastProps) => {
      setVisible(true);

      if (variant) {
        setVariant(variant);
      } else setVariant(ToastVariant.Information);
      if (icon) {
        setIcon(icon);
      } else setIcon(false);
      if (action) {
        setAction(action);
      } else setAction(undefined);
      if (closable) {
        setClosable(closable);
      } else setClosable(false);
      if (timeOut) {
        setTimeout(timeOut);
      } else if (timeOut === false) {
        setTimeout(false);
      }
      if (message) {
        setMessage(message);
      }
    },
    [setVisible, setVariant, setIcon, setAction, setClosable, setTimeout, setMessage],
  );

  const Toast = ({ className, ...rest }: { className: string; [rest: string]: any }) => {
    const toastRef = useRef<HTMLDivElement>(null);

    const handleClickOutside = (e: any) => {
      if (!!toastRef.current && !toastRef.current.contains(e.target)) {
        setVisible(false);
      }
    };

    useEffect(() => {
      window.addEventListener('mousedown', (e: MouseEvent) => {
        handleClickOutside(e);
      });
      return () => {
        window.removeEventListener('mousedown', (e: MouseEvent) => {
          handleClickOutside(e);
        });
      };
    }, []);

    useEffect(() => {
      if (typeof timeOut === 'number' && !closable && action === undefined) {
        const interval = setInterval(() => {
          setVisible(false);
        }, timeOut);
        return () => {
          clearInterval(interval);
        };
      }
      return () => {};
    }, []);

    return (
      <Container
        ref={toastRef}
        aria-hidden={visible}
        aria-label="Varsling"
        className={className}
        variant={variant}
        visible={visible}
        {...rest}
      >
        {!action && !closable && icon && (
          <ToastIcon>
            <Icon iconVariant={variant} />
          </ToastIcon>
        )}
        <ToastText variant={TextVariant.ArticleText}>{message}</ToastText>
        {action !== undefined && (
          <ActionButton onClick={action.onClick}>
            <ButtonText variant={TextVariant.ArticleText}>{action.text}</ButtonText>
          </ActionButton>
        )}
        {action === undefined && closable && (
          <CloseButton onClick={() => setVisible(false)}>
            <Close size={24} />
          </CloseButton>
        )}
      </Container>
    );
  };

  return { showToast, Toast, closeToast };
};

const CenterSvg = css`
  svg {
    display: flex;
    align-items: center;
  }
`;

const ResetButton = css`
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  background: transparent;
  color: inherit;
  font: inherit;
  line-height: normal;
  appearance: none;
  touch-action: manipulation;
`;

const Container = styled.div<{ variant?: ToastVariant; visible: boolean }>`
  display: ${(props) => (props.visible ? 'flex' : 'none')};
  color: ${({ theme }) =>
    theme.typography ? theme.typography.defaultTextInverted : themeLight.typography.defaultTextInverted};
  align-items: center;
  width: fit-content;
  position: fixed;
  bottom: ${spacing.space24};
  left: 0;
  right: 0;
  margin: 0 auto;
  border-radius: ${radius.s};
  padding: ${spacing.space12} ${spacing.space16};
  z-index: 9999;
  background-color: ${({ theme }) =>
    theme.componentColors
      ? theme.componentColors.cta.secondaryInverted
      : themeLight.componentColors.cta.secondaryInverted};

  ${({ variant }) =>
    variant === 'information' &&
    css`
      background-color: ${({ theme }) =>
        theme.componentColors
          ? theme.componentColors.cta.secondaryInverted
          : themeLight.componentColors.cta.secondaryInverted};
    `}

  ${({ variant }) =>
    variant === 'success' &&
    css`
      background-color: ${({ theme }) =>
        theme.componentColors ? theme.componentColors.alert.success : themeLight.componentColors.alert.success};
    `}

  ${({ variant }) =>
    variant === 'error' &&
    css`
      background-color: ${({ theme }) =>
        theme.componentColors ? theme.componentColors.alert.error : themeLight.componentColors.alert.error};
    `}
`;

const ToastText = styled(Text)`
  display: inline-flex;
  margin: 0;
`;

const ToastIcon = styled.div`
  display: inline-flex;
  ${CenterSvg}

  svg {
    margin-right: ${spacing.space12};
  }
`;

const ActionButton = styled.button`
  ${ResetButton};
  margin-left: ${spacing.space24};
  cursor: pointer;
`;

const ButtonText = styled(Text)`
  margin: 0;
  text-decoration: underline;
`;

const CloseButton = styled.button`
  ${ResetButton};
  cursor: pointer;
  margin-left: ${spacing.space16};
  ${CenterSvg}
`;

import React, { useEffect, useState, forwardRef, ForwardRefExoticComponent, RefAttributes } from 'react';
import styled, { css } from 'styled-components';
import { fontStyle, spacing, themeLight, icons } from '@naf/theme';
import { Text, TextVariant } from '@naf/text';
import { KeyboardArrowDown } from '@styled-icons/material/KeyboardArrowDown';

export interface AccordionProps {
  /**
   * Content to be shown when the component is expanded.
   */
  children: any;

  /**
   * Title of the component's clickable field.
   */
  label: string;

  /**
   * Max-width of the container around the component. If not set, the component will stretch out to the maximum available width.
   */
  maxWidth?: number;

  /**
   * Defines the size of the component, including font size and spacing.
   */
  size?: 'small' | 'medium' | 'large';

  /**
   * Shall the component be loaded in expanded state?
   */
  openByDefault?: boolean;

  /**
   * Additional functionality when the content is opened.
   */

  onOpen?: () => void;
  className?: string;
  [rest: string]: any;
}

export const Accordion: ForwardRefExoticComponent<AccordionProps & RefAttributes<HTMLDivElement>> = forwardRef<
  HTMLDivElement,
  AccordionProps
>(({ children, label, size = 'medium', maxWidth, onOpen, openByDefault, className, ...rest }: AccordionProps, ref) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (openByDefault) {
      setOpen(openByDefault);
    }
  }, [openByDefault]);

  const labelVariant = (size: string) => {
    switch (size) {
      case 'small':
        return TextVariant.BodyTextHeader;
      case 'medium':
        return TextVariant.ArticleTextHeader;
      case 'large':
        return TextVariant.Header3;
      default:
        return TextVariant.ArticleTextHeader;
    }
  };

  return (
    <Container ref={ref} maxWidth={maxWidth} size={size} className={className} {...rest}>
      <StyledText tag="h3" variant={labelVariant(size)}>
        <Button
          onClick={() => {
            setOpen(!open);
            onOpen && onOpen();
          }}
          aria-expanded={open}
          type="button"
        >
          {label}
          <IconWrap isOpen={open}>
            <KeyboardArrowDown size={30} />
          </IconWrap>
        </Button>
      </StyledText>
      {open && <Content>{children}</Content>}
    </Container>
  );
});

export default Accordion;

const Button = styled.button`
  /* reset style */
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  background: transparent;
  color: inherit;
  font: inherit;
  line-height: normal;
  appearance: none;
  touch-action: manipulation;

  /* style */
  width: 100%;
  text-align: start;
  padding-right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:hover {
    cursor: pointer;
  }
`;

const IconWrap = styled.div<any>`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: ${spacing.space8};

  ${({ isOpen }) =>
    isOpen &&
    css`
      transform: rotate(180deg);
      transition-duration: 0.3s;
    `}

  ${({ isOpen }) =>
    !isOpen &&
    css`
      transform: rotate(0);
      transition-duration: 0.3s;
    `}
  
  svg {
    font-size: ${icons.m};
  }
`;

const Content = styled.section`
  ${fontStyle.article.articleText};
`;

const StyledText = styled(Text)`
  margin: 0;
`;

const Container = styled.div<any>`
  border-bottom: 1px solid;
  border-color: ${({ theme }) => (theme.border ? theme.border.subtle : themeLight.border.subtle)};

  ${({ maxWidth }) =>
    maxWidth &&
    css`
      max-width: ${maxWidth}px;
    `}

  /* small */
  ${({ size }) =>
    size === 'small' &&
    css`
      ${Button} {
        line-height: 1;
        padding: ${spacing.space16} 0;
      }
      ${Content} {
        ${fontStyle.bodyText.bodyText};
        padding-bottom: ${spacing.space24};
      }
    `}

  /* medium */
    ${({ size }) =>
    size === 'medium' &&
    css`
      ${Button} {
        padding: ${spacing.space24} 0;
      }
      ${Content} {
        padding-bottom: ${spacing.space32};
      }
    `}

  /* large */
  ${({ size }) =>
    size === 'large' &&
    css`
      ${Button} {
        padding: ${spacing.space24} 0;
      }
      ${Content} {
        padding-bottom: ${spacing.space32};
      }
    `}
`;

import { nafThemeLight } from './themeLight';
import { nafThemeVisualBreak } from './themeVisualBreak';
import { nafThemeJustSuper } from './themeJustSuper';
import { desktopFontStyles } from './desktopFontStyle';
import { mobileFontStyles } from './mobileFontStyle';

const theme = {
  breakpoints: {
    s: '576px',
    m: '768px',
    l: '992px',
    xl: '1216px',
    xxl: '1920px',
  },
  icons: {
    s: '16px',
    m: '24px',
    l: '32px',
  },
  radius: {
    s: '2px',
    m: '4px',
    l: '6px',
  },
  spacing: {
    space4: '4px',
    space8: '8px',
    space12: '12px',
    space16: '16px',
    space20: '20px',
    space24: '24px',
    space32: '32px',
    space40: '40px',
    space48: '48px',
    space56: '56px',
    space64: '64px',
    space80: '80px',
    space120: '120px',
    space160: '160px',
  },
  animation: {
    easeIn: 'cubic-bezier(0.3, 0, 0.2, 1)',
    easeOut: 'cubic-bezier(0.8, 0, 0.7, 1)',
    brandSpeed: 600,
    uiSpeed: 300,
  },
};

//theme values
export const breakpoints = theme.breakpoints;
export const icons = theme.icons;
export const radius = theme.radius;
export const spacing = theme.spacing;
export const animation = theme.animation;
export const fontStyle = desktopFontStyles;
export const mobile = mobileFontStyles;
export const themeLight = nafThemeLight;
export const themeVisualBreak = nafThemeVisualBreak;
export const themeJustSuper = nafThemeJustSuper;
export type { NafThemeType } from './themeTypes';

import React, { useState, useEffect, useCallback, forwardRef, ForwardRefExoticComponent, RefAttributes } from 'react';
import styled, { css } from 'styled-components';
import { fontStyle, spacing, radius, themeLight } from '@naf/theme';
import { Dropdown } from './dropdown';
import { Text } from '@naf/text';

export interface PhoneProps {
  /**
   * List over countries and country codes for the dropdown.
   */
  countries: {
    name: string;
    code: string;
  }[];

  /**
   * Selected country when component is rendered.
   */
  selectedCountry: {
    name: string;
    code: string;
  };

  /**
   * Country that will be shown at the top of the dropdown.
   */
  preferredCountries?: {
    name: string;
    code: string;
  }[];

  /**
   * Set to give the number field content when the component is rendered.
   */
  value?: number | string;

  /**
   * Function that is called when the content of the number field changes.
   */
  onChange?: (number: { code: string; number: string }) => void;

  /**
   * Set to illustrate an error state.
   */
  error?: boolean;

  /**
   * Text that is shown in the number field when the component is rendered.
   */
  placeholder?: string;

  /**
   * Sets the number field as inactive and changes styling.
   */
  disabled?: boolean;

  /**
   * Set a label that will be read by screen readers if no other visible label is available.
   */
  ariaLabel?: string;

  /**
   * Set the width of the component from the default value (256px).
   */
  width?: number;

  /**
   * Other props you want to send to the number field. For more info: https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/tel
   */
  [rest: string]: any;
  className?: string;
}

export const Phone: ForwardRefExoticComponent<PhoneProps & RefAttributes<HTMLDivElement>> = forwardRef<
  HTMLDivElement,
  PhoneProps
>(
  (
    {
      countries,
      selectedCountry,
      preferredCountries,
      value,
      onChange,
      onSubmit,
      error = false,
      placeholder,
      disabled = false,
      ariaLabel,
      width = 256,
      className,
      ...rest
    }: PhoneProps,
    ref,
  ) => {
    const [number, setNumber] = useState<number | string>('');
    const [isFocused, setIsFocused] = useState(false);
    const [selected, setSelected] = useState(selectedCountry);

    const setFocus = useCallback(() => {
      setIsFocused(true);
    }, [setIsFocused]);

    const loseFocus = useCallback(() => {
      setIsFocused(false);
    }, [setIsFocused]);

    const handleChange = (e: React.ChangeEvent<any>) => {
      if (!onChange) return;
      setNumber(e.target.value);
      onChange({ code: selected.code, number: e.target.value });
    };

    useEffect(() => {
      if (value) {
        setNumber(value);
      }
      return;
    }, [value]);

    return (
      <Container ref={ref} className={className} width={width} focus={isFocused} error={error} disabled={disabled}>
        {countries && countries.length > 1 && (
          <Dropdown
            disabled={disabled}
            width={width}
            countries={countries}
            preferredCountries={preferredCountries}
            selected={selected}
            handleSelect={(country: React.SetStateAction<{ name: string; code: string }>) => setSelected(country)}
          />
        )}
        {countries && countries.length === 1 && <CountryCode>(+{countries[0].code})</CountryCode>}
        <PhoneInput
          value={number}
          disabled={disabled}
          placeholder={placeholder}
          onChange={handleChange}
          aria-label={ariaLabel}
          type="tel"
          onFocus={setFocus}
          onBlur={loseFocus}
          {...rest}
        />
      </Container>
    );
  },
);

export default Phone;

const Container = styled.div<any>`
  height: ${spacing.space48};
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: center;
  width: ${(props) => props.width}px;
  background: ${({ theme }) => (theme.background ? theme.background.default : themeLight.background.default)};
  border: 1px solid;
  border-color: ${({ theme }) => (theme.border ? theme.border.heavy : themeLight.border.heavy)};
  box-sizing: border-box;
  border-radius: ${radius.s};

  * {
    font-variant-numeric: lining-nums !important;
  }

  ${({ focus, error }) =>
    focus &&
    !error &&
    css`
      border: 2px solid;
      > div,
      p {
        margin-left: -1px;
      }
    `}

  ${({ error }) =>
    error &&
    css`
      border: 2px solid;
      border-color: ${({ theme }) =>
        theme.componentColors ? theme.componentColors.alert.error : themeLight.componentColors.alert.error};
      ~ div {
        margin-left: -1px; // Consistent layout with 2px border
      }
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      background-color: ${({ theme }) =>
        theme.componentColors
          ? theme.componentColors.inputElement.backgroundDisabled
          : themeLight.componentColors.inputElement.backgroundDisabled};
      border-color: ${({ theme }) => (theme.border ? theme.border.heavy : themeLight.border.heavy)};
      color: ${({ theme }) => (theme.typography ? theme.typography.disabledText : themeLight.typography.disabledText)};
      -webkit-text-fill-color: ${({ theme }) =>
        theme.typography ? theme.typography.disabledText : themeLight.typography.disabledText};
      opacity: 1;
    `}
`;

const PhoneInput = styled.input<any>`
  background: transparent;
  border: none;
  overflow: auto;
  padding-left: ${spacing.space12};
  height: calc(100% - 2px);
  ${fontStyle.article.articleText};

  &:focus:not([disabled]) {
    outline: none;
  }

  ::placeholder {
    color: ${({ theme }) => (theme.typography ? theme.typography.subtleText : themeLight.typography.subtleText)};
  }

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
    `}
`;

const CountryCode = styled(Text)`
  margin: 0;
  padding-left: ${spacing.space8};
`;

import React, { useState, useEffect, forwardRef, ForwardRefExoticComponent, RefAttributes } from 'react';
import styled, { css } from 'styled-components';
import { fontStyle, spacing, radius, themeLight } from '@naf/theme';

export interface InputProps {
  /**
   * Formatting of the input field. `'input'` for a single line and `'textarea'` for multiple lines.
   */
  as?: 'input' | 'textarea';

  /**
   * Set to give the text field content when the component is rendered.
   */
  value?: string;

  /**
   * Set maximum number of characters in the field. Illustrated in a counter below the text field.
   */
  maxCharacters?: number;

  /**
   * Function that is called when the user types in information.
   */
  onChange?: (val: any) => void;

  /**
   * Used to illustrate an error state.
   */
  error?: boolean;

  /**
   * Text that is shown in the text field when the component is rendered.
   */
  placeholder?: string;

  /**
   * Set the text field as inactive and changes styling.
   */
  disabled?: boolean;

  /**
   * Set aria-label to be read by screen readers if other visible label is not available.
   */
  ariaLabel?: string;

  /*
   * Set the width of the text field, default is 256px.
   */
  width?: number;

  /**
   * Other props you want to send to the text field. For more info: https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input
   */
  [rest: string]: any;

  className?: string;
}

export const Input: ForwardRefExoticComponent<InputProps & RefAttributes<HTMLDivElement>> = forwardRef<
  HTMLDivElement,
  InputProps
>(
  (
    {
      as = 'input',
      value = '',
      maxCharacters,
      onChange,
      error = false,
      placeholder,
      disabled = false,
      ariaLabel,
      width = 256,
      className,
      ...rest
    }: InputProps,
    ref,
  ) => {
    const [count, setCount] = useState(0);
    const [text, setText] = useState(value);

    const handleChange = (e: React.ChangeEvent<any>) => {
      if (!onChange) return;
      if (maxCharacters && e.target.value.length <= maxCharacters) {
        setCount(e.target.value.length);
        setText(e.target.value);
        onChange(e.target.value);
      }
      if (!maxCharacters) {
        setText(e.target.value);
        onChange(e.target.value);
      }
    };

    useEffect(() => {
      if (maxCharacters && value.length <= maxCharacters) {
        setCount(value.length);
        setText(value);
      }
      if (maxCharacters && value.length > maxCharacters) {
        setText(value.slice(0, maxCharacters));
        setCount(value.slice(0, maxCharacters).length);
      }
      if (!maxCharacters) {
        setText(value);
      }
    }, [value, maxCharacters]);

    return (
      <Container className={className} width={width}>
        <StyledInput
          as={as}
          value={text}
          placeholder={placeholder}
          error={error}
          disabled={disabled}
          onChange={handleChange}
          aria-label={ariaLabel}
          ref={ref}
          {...rest}
        />
        {maxCharacters && (
          <Count>
            {count}/{maxCharacters} tegn
          </Count>
        )}
      </Container>
    );
  },
);

export default Input;

const errorStyle = css`
  border: 2px solid;
  border-color: ${({ theme }) =>
    theme.componentColors ? theme.componentColors.alert.error : themeLight.componentColors.alert.error};
  padding: calc(${spacing.space8} - 1px); // Consistent layout with 2px border
`;

const Container = styled.div<{ className?: string; width: number }>`
  display: flex;
  flex-direction: column;
  position: relative;
  width: ${(props) => props.width}px;
`;

const StyledInput = styled.input<any>`
  background: ${({ theme }) => (theme.background ? theme.background.default : themeLight.background.default)};
  border: 1px solid;
  border-color: ${({ theme }) => (theme.border ? theme.border.heavy : themeLight.border.heavy)};
  box-sizing: border-box;
  border-radius: ${radius.s};
  padding: ${spacing.space8};
  ${fontStyle.article.articleText};
  outline: 0;
  font-variant-numeric: lining-nums !important;

  &:disabled {
    background-color: ${({ theme }) =>
      theme.componentColors
        ? theme.componentColors.inputElement.backgroundDisabled
        : themeLight.componentColors.inputElement.backgroundDisabled};
    border-color: ${({ theme }) => (theme.border ? theme.border.heavy : themeLight.border.heavy)};
    color: ${({ theme }) => (theme.typography ? theme.typography.disabledText : themeLight.typography.disabledText)};
    -webkit-text-fill-color: ${({ theme }) =>
      theme.typography ? theme.typography.disabledText : themeLight.typography.disabledText};
    cursor: not-allowed;
    opacity: 1;
  }

  &:focus {
    border-color: ${({ theme }) =>
      theme.typography ? theme.typography.defaultText : themeLight.typography.defaultText};
  }

  ${({ as }) =>
    as === 'textarea' &&
    css`
      min-height: ${spacing.space160};
    `}

  ${({ as }) =>
    as === 'input' &&
    css`
      height: ${spacing.space48};
    `}

  ::placeholder {
    color: ${({ theme }) => (theme.typography ? theme.typography.subtleText : themeLight.typography.subtleText)};
  }

  ${({ disabled }) =>
    disabled &&
    css`
      background-color: ${({ theme }) =>
        theme.componentColors
          ? theme.componentColors.inputElement.backgroundDisabled
          : themeLight.componentColors.inputElement.backgroundDisabled};
      border-color: ${({ theme }) => (theme.border ? theme.border.heavy : themeLight.border.heavy)};
      color: ${({ theme }) => (theme.typography ? theme.typography.disabledText : themeLight.typography.disabledText)};
      -webkit-text-fill-color: ${({ theme }) =>
        theme.typography ? theme.typography.disabledText : themeLight.typography.disabledText};
      cursor: not-allowed;
      opacity: 1;
    `}

  ${({ error }) =>
    error &&
    css`
      ${errorStyle};
    `}

  &:invalid {
    ${errorStyle};
  }
`;

const Count = styled.span`
  ${fontStyle.bodyText.small};
  display: flex;
  align-self: flex-end;
  bottom: calc(
    -${fontStyle.bodyText.small['font-size']} * ${fontStyle.bodyText.small['line-height']} - ${spacing.space8}
  );
  color: ${({ theme }) => (theme.typography ? theme.typography.subtleText : themeLight.typography.subtleText)};
`;

import { nafColor } from '@nafcore/theme';
import { NafThemeType } from './themeTypes';

const { signature, primary, secondary, neutral, information } = nafColor;

const extra = {
  warmYellow: '#fab70a',
  orange: '#ff9700',
  darkOrange: '#ef7d0f',
};

export const nafThemeJustSuper: NafThemeType = {
  fontFamilies: {
    default: 'Poppins, -system-ui, system-ui, sans-serif',
    quote: 'questa-slab, -system-ui, system-ui, sans-serif',
    headers: 'Poppins, -system-ui, system-ui, sans-serif',
    special: 'questa-sans, -system-ui, system-ui, sans-serif',
  },
  typography: {
    defaultText: '#271F00',
    defaultTextInverted: signature.black,
    subtleText: neutral.neutral4,
    subtleTextInverted: neutral.neutral3,
    brandText: '#271F00',
    brandTextInverted: signature.black,
    disabledText: neutral.neutral4,
    disabledTextInverted: neutral.neutral3,
    textLink: extra.orange,
    textLinkInverted: extra.orange,
  },
  background: {
    default: signature.white,
    important: signature.white,
    brand: signature.white,
    brandInverted: signature.yellow40,
  },
  componentColors: {
    extras: {
      accent: extra.darkOrange,
    },
    interactiveElement: {
      active: extra.warmYellow,
      disabled: neutral.neutral3,
      hover: signature.yellow20,
    },
    inputElement: {
      backgroundDisabled: neutral.neutral1,
      backgroundDefault: signature.white,
      borderDefault: neutral.neutral4,
      borderActive: signature.black,
    },
    cta: {
      primary: '#da9f00',
      primaryHover: extra.orange,
      secondary: extra.orange,
      secondaryHover: extra.orange,
      secondaryInverted: extra.warmYellow,
      secondaryInvertedHover: extra.orange,
      disabled: neutral.neutral1,
    },
    navigation: {
      active: extra.orange,
      inactive: neutral.neutral2,
    },
    alert: {
      successBackground: information.succesLight,
      success: information.successDark,
      errorBackground: information.errorLight,
      error: information.errorDark,
      warning: information.warning,
      warningBackground: information.warningLight,
      info: information.information,
      infoLight: information.informationLight,
    },
  },
  maps: {
    pointOfInterest: {
      primary: {
        light: primary.kart,
        medium: primary.park,
        dark: primary.spruce,
      },
      active: extra.warmYellow,
      extra1: {
        light: secondary.cloudberry,
        medium: '#ff6666',
        dark: '#330f1a',
      },
      extra2: {
        light: '#ababed',
        medium: '#755cff',
        dark: '#383373',
      },
    },
  },
  border: {
    default: neutral.neutral3,
    subtle: neutral.neutral2,
    defaultInverted: neutral.neutral4,
    subtleInverted: neutral.neutral5,
    heavy: neutral.neutral4,
    heavyInverted: neutral.neutral2,
  },
};

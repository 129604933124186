import React, { FC } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { themeLight, spacing } from '@naf/theme';

type VariantType = 'primary' | 'neutral';
type SizeType = 'small' | 'standard';

export interface LoaderProps {
  /**
   * Setter styling for spinneren. Fargene hentes fra NAF's designguide.
   */
  variant?: VariantType;
  /**
   * Spinnerens diameter i piksler.
   */
  size?: SizeType;
}

export const Loader: FC<LoaderProps> = ({ variant = 'primary', size = 'standard' }: LoaderProps) => (
  <Spinner variant={variant} size={size} />
);

const spin = keyframes`
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
`;

const Spinner = styled.div<{ variant: VariantType; size: SizeType }>`
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  border: 1.5px solid;
  border-radius: 50%;
  box-sizing: border-box;
  animation: ${spin} 0.6s linear infinite;

  ${({ size }) =>
    size === 'standard' &&
    css`
      width: ${spacing.space32};
      height: ${spacing.space32};
      border: 3px solid;
    `}

  ${({ size }) =>
    size === 'small' &&
    css`
      width: ${spacing.space16};
      height: ${spacing.space16};
      border: 1.5px solid;
    `}

  ${({ variant }) =>
    variant === 'primary' &&
    css`
      border-color: ${({ theme }) =>
        theme.componentColors
          ? theme.componentColors.navigation.inactive
          : themeLight.componentColors.navigation.inactive};
      border-top-color: ${({ theme }) =>
        theme.componentColors ? theme.componentColors.navigation.active : themeLight.componentColors.navigation.active};
    `}
  
  ${({ variant }) =>
    variant === 'neutral' &&
    css`
      border-color: ${({ theme }) =>
        theme.componentColors
          ? theme.componentColors.navigation.inactive
          : themeLight.componentColors.navigation.inactive};
      border-top-color: ${({ theme }) =>
        theme.typography ? theme.typography.defaultText : themeLight.typography.defaultText};
    `}
`;

export default Loader;

import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { fontStyle, spacing } from '@naf/theme';
import { Text, TextVariant } from '@naf/text';
import { Tooltip } from '@naf/parts';

export interface LabelProps {
  /**
   * Set the title of the label.
   */
  children: any;

  /**
   * Set a short, informative text that is shown using a tool tip icon.
   */
  toolTip?: string;

  /**
   * Ties the label to an input field.
   */
  htmlFor?: string;

  /**
   * Used to give a short explanation to the field.
   */
  subHeader?: string;

  /**
   * Set to change the width of the component from the default value (256px).
   */
  width?: number;

  /**
   * Set to change the font style of the title.
   */
  variant?: TextVariant;

  /**
   * Other props you want to send to the label component.
   */
  [rest: string]: any;

  className?: string;
}

export const Label: FC<LabelProps> = ({
  children,
  toolTip,
  htmlFor,
  subHeader,
  width = 256,
  variant = TextVariant.Small,
  className,
  ...rest
}: LabelProps) => {
  return (
    <Title className={className} htmlFor={htmlFor} width={width} {...rest}>
      <TitleWrap>
        <TitleText variant={variant}>{children}</TitleText>
        {toolTip && <Tooltip text={toolTip} />}
      </TitleWrap>
      {subHeader && <Helper>{subHeader}</Helper>}
    </Title>
  );
};

export default Label;

const Title = styled.label<any>`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: ${spacing.space8};
  width: ${(props) => props.width}px;
`;

const TitleWrap = styled.div`
  display: flex;
  align-items: flex-end;
  flex-basis: 100%;
  margin: 0;
  position: relative;
`;

const TitleText = styled(Text)<any>`
  margin: 0;
  margin-right: ${spacing.space8};

  ${({ variant }) =>
    variant === 'small' &&
    css`
      line-height: ${spacing.space16};
    `}
`;

const Helper = styled.h6`
  margin: 0;
  margin-top: ${spacing.space4};
  ${fontStyle.article.caption};
  line-height: ${spacing.space16};
  flex-basis: 100%;
`;

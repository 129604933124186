import React, { forwardRef, HTMLAttributes, ForwardRefExoticComponent, RefAttributes, useContext } from 'react';
import styled, { ThemeContext, css } from 'styled-components';
import { fontStyle, mobile, breakpoints, themeLight } from '@naf/theme';
import { NafFontStyleBase } from '@naf/theme/dist/types/theme/fontTypes';
import { NafThemeType } from '@naf/theme/dist/types/theme/themeTypes';

export enum TextVariant {
  Display = 'display',
  Header1 = 'header1',
  Header2 = 'header2',
  Header3 = 'header3',
  SubHeader = 'subHeader',
  ArticleText = 'articleText',
  ArticleTextHeader = 'articleTextHeader',
  ArticleTextSubHeader = 'articleTextSubHeader',
  ArticleTextLarge = 'articleTextLarge',
  ArticleTextLargeHeader = 'articleTextLargeHeader',
  Caption = 'caption',
  CaptionHeader = 'captionHeader',
  Ingress = 'ingress',
  Blockquote = 'blockquote',
  BlockquoteLarge = 'blockquoteLarge',
  BodyText = 'bodyText',
  BodyTextHeader = 'bodyTextHeader',
  BodyTextSubHeader = 'bodyTextSubHeader',
  Small = 'small',
  Tiny = 'tiny',
  CardHeader = 'cardHeader',
  Tag = 'tag',
  TagSmall = 'tagSmall',
  Button = 'button',
  ButtonSmall = 'buttonSmall',
  DataLabel = 'dataLabel',
  Giga = 'giga',
  AnchorLink = 'anchorLink',
}

export enum Breakpoint {
  S = 's',
  M = 'm',
  L = 'l',
  XL = 'xl',
}

export type NafTextElement = HTMLParagraphElement | HTMLSpanElement | HTMLHeadingElement;

export interface TextProps extends HTMLAttributes<NafTextElement> {
  /**
   * Set an HTML-tag for the component on the client. If not set, the tag will be chosen automatically based on `variant`.
   */
  tag?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'span' | null;

  /**
   * Set the text style, font and size.
   */
  variant?: TextVariant;

  /**
   * Set breakpoint for when the text scales to mobile font. Default is `576px`.
   */
  breakpoint?: Breakpoint;
}

export const Text: ForwardRefExoticComponent<TextProps & RefAttributes<NafTextElement>> = forwardRef<
  NafTextElement,
  TextProps
>(({ tag = null, variant = TextVariant.ArticleText, breakpoint = Breakpoint.S, ...rest }: TextProps, ref) => {
  const defaultTag = (variant: TextVariant) => {
    switch (variant) {
      case TextVariant.Display:
        return 'h1';
      case TextVariant.Header1:
        return 'h1';
      case TextVariant.Giga:
        return 'h1';
      case TextVariant.Header2:
        return 'h2';
      case TextVariant.Header3:
        return 'h3';
      case TextVariant.SubHeader:
        return 'h3';
      case TextVariant.ArticleTextHeader:
        return 'h4';
      case TextVariant.ArticleTextSubHeader:
        return 'h5';
      case TextVariant.ArticleTextLargeHeader:
        return 'h4';
      case TextVariant.CaptionHeader:
        return 'h5';
      case TextVariant.BodyTextHeader:
        return 'h6';
      case TextVariant.Tag:
        return 'span';
      case TextVariant.TagSmall:
        return 'span';
      case TextVariant.Button:
        return 'span';
      case TextVariant.ButtonSmall:
        return 'span';
      default:
        return 'p';
    }
  };

  const textBreakpoint = (breakpoint: Breakpoint): string => {
    switch (breakpoint) {
      case Breakpoint.S:
        return breakpoints.s;
      case Breakpoint.M:
        return breakpoints.m;
      case Breakpoint.L:
        return breakpoints.l;
      case Breakpoint.XL:
        return breakpoints.xl;
      default:
        return breakpoints.s;
    }
  };

  const theme = useContext<NafThemeType>(ThemeContext);

  const textStyles: {
    [name in TextVariant]: NafFontStyleBase;
  } = {
    display: {
      'font-family': `${
        theme ? theme.fontFamilies.headers : themeLight.fontFamilies.headers
      }, -system-ui, system-ui, sans-serif`,
      ...fontStyle.headers.display,
    },
    giga: {
      'font-family': `${
        theme ? theme.fontFamilies.headers : themeLight.fontFamilies.headers
      }, -system-ui, system-ui, sans-serif`,
      ...fontStyle.headers.giga,
    },
    header1: {
      'font-family': `${
        theme ? theme.fontFamilies.headers : themeLight.fontFamilies.headers
      }, -system-ui, system-ui, sans-serif`,
      ...fontStyle.headers.header1,
    },
    header2: {
      'font-family': `${
        theme ? theme.fontFamilies.headers : themeLight.fontFamilies.headers
      }, -system-ui, system-ui, sans-serif`,
      ...fontStyle.headers.header2,
    },
    header3: {
      'font-family': `${
        theme ? theme.fontFamilies.headers : themeLight.fontFamilies.headers
      }, -system-ui, system-ui, sans-serif`,
      ...fontStyle.headers.header3,
    },
    subHeader: {
      'font-family': `${
        theme ? theme.fontFamilies.default : themeLight.fontFamilies.default
      }, -system-ui, system-ui, sans-serif`,
      ...fontStyle.headers.subHeader,
    },
    articleText: {
      'font-family': `${
        theme ? theme.fontFamilies.default : themeLight.fontFamilies.default
      }, -system-ui, system-ui, sans-serif`,
      ...fontStyle.article.articleText,
    },
    articleTextHeader: {
      'font-family': `${
        theme ? theme.fontFamilies.default : themeLight.fontFamilies.default
      }, -system-ui, system-ui, sans-serif`,
      ...fontStyle.article.articleTextHeader,
    },
    articleTextSubHeader: {
      'font-family': `${
        theme ? theme.fontFamilies.default : themeLight.fontFamilies.default
      }, -system-ui, system-ui, sans-serif`,
      ...fontStyle.article.articleTextSubHeader,
    },
    articleTextLarge: {
      'font-family': `${
        theme ? theme.fontFamilies.default : themeLight.fontFamilies.default
      }, -system-ui, system-ui, sans-serif`,
      ...fontStyle.article.articleTextLarge,
    },
    articleTextLargeHeader: {
      'font-family': `${
        theme ? theme.fontFamilies.default : themeLight.fontFamilies.default
      }, -system-ui, system-ui, sans-serif`,
      ...fontStyle.article.articleTextLargeHeader,
    },
    caption: {
      'font-family': `${
        theme ? theme.fontFamilies.default : themeLight.fontFamilies.default
      }, -system-ui, system-ui, sans-serif`,
      ...fontStyle.article.caption,
    },
    captionHeader: {
      'font-family': `${
        theme ? theme.fontFamilies.default : themeLight.fontFamilies.default
      }, -system-ui, system-ui, sans-serif`,
      ...fontStyle.article.captionHeader,
    },
    ingress: {
      'font-family': `${
        theme ? theme.fontFamilies.quote : themeLight.fontFamilies.quote
      }, -system-ui, system-ui, sans-serif`,
      ...fontStyle.article.ingress,
    },
    blockquote: {
      'font-family': `${
        theme ? theme.fontFamilies.quote : themeLight.fontFamilies.quote
      }, -system-ui, system-ui, sans-serif`,
      ...fontStyle.article.blockquote,
    },
    blockquoteLarge: {
      'font-family': `${
        theme ? theme.fontFamilies.quote : themeLight.fontFamilies.quote
      }, -system-ui, system-ui, sans-serif`,
      ...fontStyle.article.blockquoteLarge,
    },
    bodyText: {
      'font-family': `${
        theme ? theme.fontFamilies.default : themeLight.fontFamilies.default
      }, -system-ui, system-ui, sans-serif`,
      ...fontStyle.bodyText.bodyText,
    },
    bodyTextHeader: {
      'font-family': `${
        theme ? theme.fontFamilies.default : themeLight.fontFamilies.default
      }, -system-ui, system-ui, sans-serif`,
      ...fontStyle.bodyText.bodyTextHeader,
    },
    bodyTextSubHeader: {
      'font-family': `${
        theme ? theme.fontFamilies.default : themeLight.fontFamilies.default
      }, -system-ui, system-ui, sans-serif`,
      ...fontStyle.bodyText.bodyTextSubHeader,
    },
    small: {
      'font-family': `${
        theme ? theme.fontFamilies.default : themeLight.fontFamilies.default
      }, -system-ui, system-ui, sans-serif`,
      ...fontStyle.bodyText.small,
    },
    tiny: {
      'font-family': `${
        theme ? theme.fontFamilies.default : themeLight.fontFamilies.default
      }, -system-ui, system-ui, sans-serif`,
      ...fontStyle.bodyText.tiny,
    },
    cardHeader: {
      'font-family': `${
        theme ? theme.fontFamilies.default : themeLight.fontFamilies.default
      }, -system-ui, system-ui, sans-serif`,
      ...fontStyle.componentText.cardHeader,
    },
    tag: {
      'font-family': `${
        theme ? theme.fontFamilies.default : themeLight.fontFamilies.default
      }, -system-ui, system-ui, sans-serif`,
      ...fontStyle.componentText.tag,
    },
    tagSmall: {
      'font-family': `${
        theme ? theme.fontFamilies.default : themeLight.fontFamilies.default
      }, -system-ui, system-ui, sans-serif`,
      ...fontStyle.componentText.tagSmall,
    },
    button: {
      'font-family': `${
        theme ? theme.fontFamilies.default : themeLight.fontFamilies.default
      }, -system-ui, system-ui, sans-serif`,
      ...fontStyle.componentText.button,
    },
    buttonSmall: {
      'font-family': `${
        theme ? theme.fontFamilies.default : themeLight.fontFamilies.default
      }, -system-ui, system-ui, sans-serif`,
      ...fontStyle.componentText.buttonSmall,
    },
    dataLabel: {
      'font-family': `${
        theme ? theme.fontFamilies.default : themeLight.fontFamilies.default
      }, -system-ui, system-ui, sans-serif`,
      ...fontStyle.componentText.dataLabel,
    },
    anchorLink: {
      'font-family': `${
        theme ? theme.fontFamilies.default : themeLight.fontFamilies.default
      }, -system-ui, system-ui, sans-serif`,
      ...fontStyle.article.articleText,
    },
  };

  const mobileTextStyles: {
    [name in TextVariant]: NafFontStyleBase;
  } = {
    ...textStyles,
    display: {
      'font-family': `${
        theme ? theme.fontFamilies.headers : themeLight.fontFamilies.headers
      }, -system-ui, system-ui, sans-serif`,
      ...mobile.headers.display,
    },
    giga: {
      'font-family': `${
        theme ? theme.fontFamilies.headers : themeLight.fontFamilies.headers
      }, -system-ui, system-ui, sans-serif`,
      ...mobile.headers.giga,
    },
    header1: {
      'font-family': `${
        theme ? theme.fontFamilies.headers : themeLight.fontFamilies.headers
      }, -system-ui, system-ui, sans-serif`,
      ...mobile.headers.header1,
    },
    header2: {
      'font-family': `${
        theme ? theme.fontFamilies.headers : themeLight.fontFamilies.headers
      }, -system-ui, system-ui, sans-serif`,
      ...mobile.headers.header2,
    },
    header3: {
      'font-family': `${
        theme ? theme.fontFamilies.headers : themeLight.fontFamilies.headers
      }, -system-ui, system-ui, sans-serif`,
      ...mobile.headers.header3,
    },
    subHeader: {
      'font-family': `${
        theme ? theme.fontFamilies.default : themeLight.fontFamilies.default
      }, -system-ui, system-ui, sans-serif`,
      ...mobile.headers.subHeader,
    },
    ingress: {
      'font-family': `${
        theme ? theme.fontFamilies.quote : themeLight.fontFamilies.quote
      }, -system-ui, system-ui, sans-serif`,
      ...mobile.article.ingress,
    },
    blockquote: {
      'font-family': `${
        theme ? theme.fontFamilies.quote : themeLight.fontFamilies.quote
      }, -system-ui, system-ui, sans-serif`,
      ...mobile.article.blockquote,
    },
    blockquoteLarge: {
      'font-family': `${
        theme ? theme.fontFamilies.quote : themeLight.fontFamilies.quote
      }, -system-ui, system-ui, sans-serif`,
      ...mobile.article.blockquoteLarge,
    },
    cardHeader: {
      'font-family': `${
        theme ? theme.fontFamilies.default : themeLight.fontFamilies.default
      }, -system-ui, system-ui, sans-serif`,
      ...mobile.componentText.cardHeader,
    },
  };

  return (
    <TextElement
      ref={ref}
      as={tag || defaultTag(variant)}
      styleVariant={textStyles[variant]}
      mobileStyleVariant={mobileTextStyles[variant]}
      breakpoint={textBreakpoint(breakpoint)}
      variant={variant}
      {...rest}
    />
  );
});

export default Text;

interface TextElementProps {
  breakpoint: string;
  styleVariant: NafFontStyleBase;
  mobileStyleVariant: NafFontStyleBase;
  variant: TextVariant;
}

const TextElement = styled.p<TextElementProps>`
  ${(props) => props.styleVariant};

  ${({ variant }) =>
    variant === TextVariant.AnchorLink &&
    css`
      cursor: pointer;
      border-bottom: 1px dashed
        ${({ theme }) => (theme.typography ? theme.typography.textLink : themeLight.typography.textLink)};
      &:hover {
        border-bottom: none;
        color: ${({ theme }) => (theme.typography ? theme.typography.textLink : themeLight.typography.textLink)};
      }
    `}

  @media (max-width: ${(props) => props.breakpoint}) {
    ${(props) => props.mobileStyleVariant};
  }
`;

import { nafColor } from '@nafcore/theme';
import { NafThemeType } from './themeTypes';

const { signature, primary, secondary, neutral, information } = nafColor;

export const nafThemeVisualBreak: NafThemeType = {
  fontFamilies: {
    default: 'questa-sans, -system-ui, system-ui, sans-serif',
    quote: 'questa-slab, -system-ui, system-ui, sans-serif',
    headers: 'questa-sans, -system-ui, system-ui, sans-serif',
    special: 'questa-sans, -system-ui, system-ui, sans-serif',
  },
  typography: {
    defaultText: signature.white,
    defaultTextInverted: signature.white,
    subtleText: neutral.neutral3,
    subtleTextInverted: neutral.neutral4,
    brandText: primary.dew,
    brandTextInverted: primary.spruce,
    disabledText: neutral.neutral4,
    disabledTextInverted: neutral.neutral3,
    textLink: primary.park,
    textLinkInverted: primary.kart,
  },
  background: {
    default: primary.spruce,
    important: signature.yellow20,
    brand: primary.spruce,
    brandInverted: primary.dew,
  },
  componentColors: {
    extras: {
      accent: primary.moss,
    },
    interactiveElement: {
      active: signature.yellow,
      disabled: neutral.neutral3,
      hover: signature.yellow20,
    },
    inputElement: {
      backgroundDisabled: neutral.neutral1,
      backgroundDefault: primary.spruce,
      borderDefault: neutral.neutral4,
      borderActive: signature.black,
    },
    cta: {
      primary: signature.yellow,
      primaryHover: signature.yellow40,
      secondary: primary.moss,
      secondaryHover: primary.dew,
      secondaryInverted: primary.kart,
      secondaryInvertedHover: primary.dew,
      disabled: neutral.neutral1,
    },
    navigation: {
      active: primary.kart,
      inactive: neutral.neutral2,
    },
    alert: {
      successBackground: information.succesLight,
      success: information.successDark,
      errorBackground: information.errorLight,
      error: information.errorDark,
      warning: information.warning,
      warningBackground: information.warningLight,
      info: information.information,
      infoLight: information.informationLight,
    },
  },
  maps: {
    pointOfInterest: {
      primary: {
        light: primary.kart,
        medium: primary.park,
        dark: primary.spruce,
      },
      active: signature.yellow,
      extra1: {
        light: secondary.cloudberry,
        medium: '#ff6666',
        dark: '#330f1a',
      },
      extra2: {
        light: '#ababed',
        medium: '#755cff',
        dark: '#383373',
      },
    },
  },
  border: {
    default: neutral.neutral3,
    subtle: neutral.neutral2,
    heavy: neutral.neutral4,
    defaultInverted: neutral.neutral4,
    subtleInverted: neutral.neutral5,
    heavyInverted: neutral.neutral2,
  },
};

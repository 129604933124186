import { css } from 'styled-components';
import { fontStyle, themeLight, spacing, radius } from '@naf/theme';
import { nafColor } from '@nafcore/theme';
import normalize from './global/normalize';
import typography from './global/typography';

const stylesheet = css<{ isMouseClick?: boolean }>`
  ${normalize}
  ${typography}

  html {
    overflow-x: hidden;
    background: ${({ theme }) => (theme.background ? theme.background.default : themeLight.background.default)};
    color: ${({ theme }) => (theme.typography ? theme.typography.defaultText : themeLight.typography.defaultText)};
  }

  main:focus {
    outline: none;
  }

  body {
    min-height: 100%;
    position: relative;
    overflow-x: hidden;
    ${fontStyle.article.articleText};
  }

  a {
    color: ${({ theme }) => (theme.typography ? theme.typography.defaultText : themeLight.typography.defaultText)};
    &:visited {
      color: ${({ theme }) => (theme.typography ? theme.typography.defaultText : themeLight.typography.defaultText)};
    }
    &:hover {
      color: ${({ theme }) => (theme.typography ? theme.typography.textLink : themeLight.typography.textLink)};
      text-decoration: 'none';
    }
  }

  h1 {
    margin: ${spacing.space32} 0;
  }

  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: ${spacing.space20} 0;
  }

  a[href]:not([disabled]),
  button:not([disabled]),
  textarea:not([disabled]),
  input:not([disabled]),
  select:not([disabled]),
  iframe,
  object,
  [tabindex="0"],
  [contenteditable] {
    &:focus,
    :focus-visible {
      outline: ${(props) => (props.isMouseClick ? 0 : `2px solid ${nafColor.signature.black}`)};
      border-color: ${nafColor.signature.black};
      border-radius: ${radius.s};
    }
  }
`;

export { normalize, typography };
export default stylesheet;

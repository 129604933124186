import React, { FC, createContext, useContext, ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { fontStyle, spacing, themeLight } from '@naf/theme';
import { nafColor } from '@nafcore/theme';

//TextList

const TextListContext = createContext('bullet');

export interface TextListProps {
  /**
   * List content.
   */
  children?: ReactNode;

  /**
   * Formatting of list items.
   */
  variant?: 'bullet' | 'number' | 'check';

  /**
   * Optional classname.
   */
  className?: string;

  /**
   * Set max-width on list container in pixels.
   */
  maxWidth?: number;
}

export const TextList: FC<TextListProps> = ({ children, variant = 'bullet', className, maxWidth }) => {
  const tag = variant === 'number' ? 'ol' : 'ul';

  return (
    <TextListContext.Provider value={variant}>
      <List as={tag} className={className} maxWidth={maxWidth}>
        {children}
      </List>
    </TextListContext.Provider>
  );
};

// TextListItem
export interface TextListItemProps {
  children: ReactNode;
  className?: string;
}
export const TextListItem: FC<TextListItemProps> = ({ children, className }) => {
  const variant = useContext(TextListContext);

  return (
    <Item variant={variant} className={className}>
      {variant === 'check' ? <CheckIcon /> : null}
      {children}
    </Item>
  );
};

// Style
const List = styled.ul<{ maxWidth: number | undefined }>`
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  padding-left: ${spacing.space24};

  ${({ maxWidth }) =>
    maxWidth &&
    css`
      max-width: ${maxWidth}px;
    `}
`;

const Item = styled.li<{ variant: string }>`
  position: relative;
  box-sizing: border-box;
  ${fontStyle.article.articleText};
  padding-left: ${spacing.space12};
  margin-bottom: ${spacing.space8};
  list-style: none;

  ${({ variant }) =>
    variant === 'bullet' &&
    css`
      &:before {
        content: '';
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background: ${({ theme }) =>
          theme.componentColors ? theme.componentColors.extras.accent : themeLight.componentColors.extras.accent};
        position: absolute;
        top: 11px; /* Visually align center  */
        left: calc(-${spacing.space16} - 1px); /* -1px to visually align center  */
      }
    `}

  ${({ variant }) =>
    variant === 'number' &&
    css`
      list-style: decimal;
    `}

  &:last-child,
    &:only-child {
    margin-bottom: 0;
  }
`;

//TODO: This is a temporary component. Should be replaced.
const CheckIcon = () => {
  return (
    <IconWrap>
      <svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M8.18305 0.307678L9.30679 1.61085L4.07014 7.69229L0.691406 3.78279L1.81515 2.47962L4.07014 5.08596L8.18305 0.307678Z"
          fill="#062E2A"
        />
      </svg>
    </IconWrap>
  );
};

const IconWrap = styled.div`
  border-radius: 50%;
  position: absolute;
  width: ${spacing.space16};
  height: ${spacing.space16};
  background: ${nafColor.primary.kart};
  top: 7px; /* Visually align center  */
  left: calc(-${spacing.space16} - 5px); /* -5px to visually align center  */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3px;
  box-sizing: border-box;

  svg {
    color: ${({ theme }) => (theme.typography ? theme.typography.defaultText : themeLight.typography.defaultText)};
    width: 100%;
    height: 100%;
  }
`;
